import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Skip = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="none" stroke="currentColor">
      <path d="M14,5 L30,16 L14,27 L14,5 Z M2,5 L11,11 L11,21 L2,27 L2,5 Z" />
    </g>
  </IconSvg>
);

Skip.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Skip.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default Skip;
