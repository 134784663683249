import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'fixed',
      top: 0,
      left: styling.sizes.gutteringSm,
      right: styling.sizes.gutteringSm,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: styling.zIndex.alert,
      width: [['unset'], '!important'],
      pointerEvents: 'none',
    },
    alert: {
      width: '100%',
      position: 'relative',
      maxWidth: 552,
      margin: [0, 'auto'],
      padding: [56, 46, 45],
      boxSizing: 'border-box',
      pointerEvents: 'all',
    },
    content: {
      position: 'relative',
      zIndex: 2,
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: -60,
      right: 0,
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
    },
    title: {
      marginBottom: 35,
    },
  },
  { name: 'alert' },
);
