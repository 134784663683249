import { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ErrorBoundary } from '../../contexts/errorCaptureContext';
import styling from '../../services/styling';
import PanelRoot from '../PanelRoot';
import Footer from './Footer';
import Header from './Header';
import useStyles from './layoutBrochure.styles';
import MainNav from './MainNav';
import MissionRestorePrompt from './MissionRestorePrompt';
import Pyramid from './Pyramid';
import SOTNav from './SOTNav';
import Spaceship from './Spaceship';

const LayoutBrochure = ({
  absHeader,
  children,
  className,
  noMainNav,
  noFooterNav,
  noSOTNav,
  pyramidPosition,
  withSpaceship,
  withPyramid,
  includeHrefPrefix,
}) => {
  const styles = useStyles();

  return (
    <>
      <div className={clsx(className, styles.wrapper)}>
        {!noMainNav && <MissionRestorePrompt />}
        {withSpaceship && <Spaceship />}
        {!noSOTNav && <SOTNav />}
        {!noMainNav && <MainNav />}
        <div
          className={clsx(styles.headerWrapper, absHeader && styles.absHeader)}
        >
          {withPyramid && <Pyramid position={pyramidPosition} />}
          <Header noLink={noMainNav} />
        </div>
        <main className={styles.body}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </main>
      </div>
      <Footer noFooterNav={noFooterNav} includeHrefPrefix={includeHrefPrefix} />
      <PanelRoot />
    </>
  );
};

LayoutBrochure.propTypes = {
  absHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  pyramidPosition: PropTypes.oneOf(['high', 'low']),
  noMainNav: PropTypes.bool,
  noFooterNav: PropTypes.bool,
  noSOTNav: PropTypes.bool,
  withSpaceship: PropTypes.bool,
  withPyramid: PropTypes.bool,
  includeHrefPrefix: PropTypes.bool,
};

LayoutBrochure.defaultProps = {
  absHeader: false,
  className: undefined,
  noMainNav: false,
  noFooterNav: false,
  noSOTNav: false,
  pyramidPosition: 'low',
  withSpaceship: false,
  withPyramid: false,
  includeHrefPrefix: false,
};

export default LayoutBrochure;
