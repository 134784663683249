import { useContext } from 'react';
import PropTypes, { string } from 'prop-types';

import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';
import InternalLink from '../../../InternalLink';
import SlantedButton from '../../../SlantedButton';
import useStyles from './navigationBar.styles';
import NavigationButton from './NavigationButton';

const NavigationBar = ({
  canSelect,
  handlePrev,
  handleNext,
  href,
  includeHrefPrefix,
}) => {
  const styles = useStyles();
  const { isDesktop } = useContext(MediaQueryContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerPoint}>
        <NavigationButton
          className={styles.prevButton}
          handleClick={handlePrev}
        />
        {canSelect && (
          <SlantedButton
            className={styles.button}
            component={includeHrefPrefix ? 'a' : InternalLink}
            to={includeHrefPrefix ? undefined : href}
            href={
              includeHrefPrefix
                ? `https://theconspiracytest.org${href}`
                : undefined
            }
            size={isDesktop ? 'lg' : 'mdFixed'}
            variant="primary"
          >
            Select
          </SlantedButton>
        )}
        <NavigationButton
          className={styles.nextButton}
          handleClick={handleNext}
          isNext
        />
      </div>
    </div>
  );
};

NavigationBar.propTypes = {
  canSelect: PropTypes.bool,
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
  includeHrefPrefix: PropTypes.bool.isRequired,
};

NavigationBar.defaultProps = {
  canSelect: true,
};

export default NavigationBar;
