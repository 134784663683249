import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: styling.zIndex.toast,
    },
    toast: {
      position: 'relative',
      maxWidth: 552,
      margin: [0, 'auto'],
      padding: 24,
      boxSizing: 'border-box',
      background: 'var(--toastBackgroundColor)',
      boxShadow: 'var(--toastBoxShadow)',
    },
    'variant-error': {
      '--toastBackgroundColor': styling.colors.theme.error,
      '--toastBoxShadow': styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.errorLg,
      ),
      '--toastTimerBarBackground': styling.colors.theme.pureBlack,
    },
    'variant-info': {
      '--toastBackgroundColor': styling.colors.theme.primaryLight,
      '--toastBoxShadow': styling.shadows.utils.boxShadowToString(
        styling.shadows.theme.primaryLightSm,
      ),
      '--toastTimerBarBackground': styling.colors.theme.pureBlack,
    },
    closeButton: {
      position: 'absolute',
      top: 24,
      right: 24,
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
    },
    title: {
      marginBottom: 10,
      paddingRight: 20 + 24,
    },
    timerBar: {
      position: 'absolute',
      height: 2,
      bottom: 0,
      left: 0,
      width: '100%',
      background: 'var(--toastTimerBarBackground)',
      transition: [['width', 'var(--toastTimerBarDuration)', 'linear']],
    },
    timerBarAnimating: {
      width: 0,
    },
  },
  { name: 'toast' },
);
