import { useCallback, useContext, useRef, useState } from 'react';

import { PLAYER_STATE_EVENTS } from '../../../../components/MediaPlayer/constants';
import { CONTROLS_MINIMAL } from '../../../../components/MediaPlayer/PlayerControls/constants';
import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';

const useHomeVideo = (sources, loopVideo) => {
  const initialPlayerState = useRef({
    playing: false,
    muted: false,
  });
  const [isPlaying, setIsPlaying] = useState(
    initialPlayerState.current.playing,
  );
  const [isInnerReady, setIsInnerReady] = useState(!loopVideo);

  // TODO: portrait video doesnt respect object fit cover :(
  const { isDesktop } = useContext(MediaQueryContext);
  const playerSources = sources?.[isDesktop ? 'desktop' : 'mobile'];
  // const playerSources = sources?.desktop;

  const onVideoStateChange = useCallback(
    (event, value) => {
      if (event === PLAYER_STATE_EVENTS.PLAYING) {
        setIsPlaying(value);
      }
    },
    [setIsPlaying],
  );

  const onInnerReady = useCallback(() => {
    setIsInnerReady(true);
  }, [setIsInnerReady]);

  return {
    initialPlayerState: initialPlayerState.current,
    playerSources,
    controls: isPlaying ? CONTROLS_MINIMAL : undefined,
    onVideoStateChange,
    isInnerReady,
    onInnerReady,
  };
};

export default useHomeVideo;
