import getOrFallbackInObj from '../../services/helpers/getOrFallbackInObject';
import styling from '../../services/styling';
import { variantMapping, variantMappingDefaultKey } from './constants';

export const getVariantComponent = (variantKey) =>
  getOrFallbackInObj(variantMapping, variantKey, variantMappingDefaultKey)
    .defaultComponent;

export const getVariants = () => {
  const variants = Object.keys(variantMapping).reduce(
    (acc, variantKey) => ({
      ...acc,
      [`variant-${variantKey}`]: {
        ...variantMapping[variantKey].styles,
        '&$withShadow': {
          ...variantMapping[variantKey].shadow,
        },
        '&$withHoverShadow': {
          ...variantMapping[variantKey].hoverShadow,
        },
      },
    }),
    {},
  );

  return variants;
};
