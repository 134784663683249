import useStyles from './spaceship.styles';

const Spaceship = () => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.spaceship} />
    </div>
  );
};

export default Spaceship;
