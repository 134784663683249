import PropTypes from 'prop-types';
import clsx from 'clsx';

import { sizeMappingDefaultKey, sizeMappingKeys } from './constants';
import useStyles from './slantedButton.styles';

const SlantedButton = ({
  children,
  className,
  isDisabled,
  component: Component,
  size,
  variant,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Component
      {...rest}
      className={clsx(
        className,
        styles.button,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        isDisabled && styles.disabled,
      )}
    >
      <div>{children}</div>
    </Component>
  );
};

SlantedButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.oneOf(sizeMappingKeys),
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

SlantedButton.defaultProps = {
  className: undefined,
  component: 'button',
  isDisabled: false,
  size: sizeMappingDefaultKey,
  variant: 'primary',
};

export default SlantedButton;
