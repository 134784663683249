import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden',
    },
    playPauseOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      cursor: 'pointer',
      zIndex: 2,
    },
    gradientOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      background: `radial-gradient(circle, ${styling.colors.theme.transparent} 0%, ${styling.colors.theme.pureBlack} 100%)`,
    },
    videoPlayerWrapper: {
      flexGrow: 1,
      position: 'relative',
      display: 'flex',
    },
    videoPlayer: {
      flexGrow: 1,
      position: 'relative',
      '& > video': {
        objectFit: 'cover',
      },
    },
  },
  { name: 'mediaPlayer' },
);
