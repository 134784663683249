import { useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';

import { generateName, getCheckedFromValue } from '../logic';

const useInputCheckbox = (onChange, value) => {
  const name = useRef(generateName(v4));

  const handleOnChange = useCallback((e) => onChange(e?.target?.checked));

  const checked = useMemo(() => getCheckedFromValue(value), [value]);

  const toggleValue = useCallback(() => onChange(!value), [value]);

  return {
    name: name.current,
    checked,
    handleOnChange,
    toggleValue,
  };
};

export default useInputCheckbox;
