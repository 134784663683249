import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    container: {
      position: 'relative',
      overflow: 'hidden',
      padding: [50, 0, 320],
      marginBottom: -240,
      pointerEvents: 'none',
    },
    list: {
      aspectRatio: '880 / 819',
      width: '100%',
      maxWidth: 1440,
      position: 'relative',
      margin: [0, 'auto'],
      [styling.breakpoints.up('sm')]: {
        aspectRatio: '1046 / 819',
      },
      [styling.breakpoints.up('md')]: {
        aspectRatio: '1706 / 819',
      },
    },
  },
  { name: 'metaCarousel' },
);
