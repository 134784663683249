import { memo } from 'react';
import PropTypes from 'prop-types';

import Markup from '../../../components/Markup';
import SlantedButton from '../../../components/SlantedButton';
import TextSwish from '../../../components/TextSwish';
import Typography from '../../../components/Typography';
import { htmlDecode } from '../../../services/helpers/string';
import useStyles from './metaAcknowledge.styles';

const MetaAcknowledge = ({ content }) => {
  const styles = useStyles();
  const { title, body, acknowledgeCta, acknowledgeCtaLink } = content;

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title} variant="primaryNav" withShadow>
        {htmlDecode(title)}
        <TextSwish isCentered />
      </Typography>
      <Typography className={styles.body} component="div" variant="subtitle">
        <Markup content={body} />
      </Typography>
      <SlantedButton
        className={styles.button}
        component="a"
        href={acknowledgeCtaLink}
        size="lg"
      >
        {acknowledgeCta}
      </SlantedButton>
    </div>
  );
};

MetaAcknowledge.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    acknowledgeCta: PropTypes.string.isRequired,
    acknowledgeCtaLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(MetaAcknowledge);
