import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import nebula from '../../../static/images/nebula-radial.webp';

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
    },
    background: {
      position: 'absolute',
      width: '100%',
      height: 893,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -45%)',
      backgroundImage: `url(${nebula})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: '1320px 893px',
    },
    carouselWrapper: {
      // Revisit extra space at top of carousel
      fontSize: 0,
      lineHeight: 0,

      height: 322,
      paddingBottom: 48,
      paddingTop: 169,
      position: 'relative',
      textAlign: 'center',
      overflow: 'hidden',
      touchAction: 'pan-y',
      userSelect: 'none',
      marginTop: -50,

      [styling.breakpoints.up('md')]: {
        height: 427,
        paddingBottom: 40,
        paddingTop: 254,
        marginTop: -70,
      },
    },
    center: {
      position: 'relative',
      margin: [0, 'auto'],
      display: 'inline-block',
    },
    buttonWrapper: {
      marginBottom: 103,
      [styling.breakpoints.up('md')]: {
        marginBottom: 115,
      },
    },
  },
  { name: 'carousel' },
);
