import PropTypes from 'prop-types';

import AnimatedNumber from '../../AnimatedNumber';
import Typography from '../../Typography';
import StatBar from './StatBar';
import useStyles from './statsDisplayBar.styles';

const StatsDisplayBar = ({ data, score }) => {
  const styles = useStyles();
  const { title = '', baseline, increase } = data;
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleScoreWrapper}>
        <Typography variant="statBarTitle">{title}</Typography>
        <AnimatedNumber value={score} variant="statBarValue" />
      </div>
      <StatBar secondaryValue={increase} value={baseline} />
    </div>
  );
};

StatsDisplayBar.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    increase: PropTypes.number.isRequired,
    baseline: PropTypes.number.isRequired,
  }).isRequired,
  score: PropTypes.number,
};

StatsDisplayBar.defaultProps = {
  score: 0,
};

export default StatsDisplayBar;
