import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import { PanelContext } from '../../../../contexts/panelContext';
import { getToggleMainNavClick, PANEL_ID } from '../logic';
import MainNavPanel from '../MainNavPanel';

const useMainNav = () => {
  const location = useLocation();
  const { openPanel, closePanel, openedPanel, registerPanel, deregisterPanel } =
    useContext(PanelContext);

  useEffect(() => {
    registerPanel(PANEL_ID, MainNavPanel);
    return () => deregisterPanel(PANEL_ID);
  }, []);

  useEffect(() => {
    closePanel();
    window.document.scrollingElement.scrollTo(0, 0);
  }, [location.pathname]);

  const isOpen = openedPanel === PANEL_ID;
  const otherPanelOpen = !!openedPanel && !isOpen;

  const toggleMainNav = useCallback(
    getToggleMainNavClick(openPanel, isOpen, closePanel),
    [openPanel, closePanel, isOpen],
  );

  return { toggleMainNav, isOpen, otherPanelOpen };
};

export default useMainNav;
