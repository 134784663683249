import { memo } from 'react';
import clsx from 'clsx';

import useMetaCarousel from './hooks/useMetaCarousel';
import { posterImages } from './constants';
import useStyles from './metaCarousel.styles';
import PosterImage from './PosterImage';

const MetaCarousel = () => {
  const styles = useStyles();
  const { selectedIndex } = useMetaCarousel();

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {posterImages.map((posterImage, i) => (
          <PosterImage
            key={posterImage}
            posterImage={posterImage}
            index={i}
            selectedIndex={selectedIndex}
            count={posterImages.length}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(MetaCarousel);
