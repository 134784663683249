import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const CaretDown = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 19 12"
    width={width}
  >
    <g fill="currentColor">
      <path d="M18.5 2.59909L16.3775 0.5L9.5 7.30176L2.62252 0.5L0.5 2.59909L9.5 11.5L18.5 2.59909Z" />
    </g>
  </IconSvg>
);

CaretDown.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

CaretDown.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 12,
  width: 19,
};

export default CaretDown;
