import Alert from '../Alert';
import Backdrop from '../Backdrop';
import useAlertRoot from './hooks/useAlertRoot';

const AlertRoot = () => {
  const { alerts, topAlert, closeAlert, onBackdropClick } = useAlertRoot();

  return (
    <>
      {alerts.map((alert) => {
        const alertKey = alert.key;
        const alertProps = alert.config || {};
        return (
          <Alert
            key={alertKey}
            id={alertKey}
            {...alertProps}
            hasTopAlert={!!topAlert}
            isIn={topAlert === alertKey}
          />
        );
      })}
      {alerts.length > 0 && (
        <Backdrop onClick={onBackdropClick} variant="alert" />
      )}
    </>
  );
};

export default AlertRoot;
