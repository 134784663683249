import { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';

import log from '../../services/log';
import { defaultAttemptData } from './constants';
import {
  handleBeginAttempt,
  handleClearAttempt,
  handleEndAttempt,
  handleSetAttemptAnswer,
} from './logic';

const useAttemptManagement = (retrieveValue, storeValue) => {
  const attemptData = useRef(retrieveValue('attempt', defaultAttemptData));

  const setAttemptData = (newAttemptData, shouldPersist = true) => {
    attemptData.current = newAttemptData;
    if (shouldPersist) {
      log.debug('persisting', newAttemptData);
      debounce(
        () => {
          storeValue('attempt', newAttemptData);
        },
        1000,
        {
          leading: true,
          trailing: true,
        },
      )();
    }
  };

  const getAttemptData = useCallback(() => attemptData.current, []);

  const beginAttempt = useCallback((missionSlug) => {
    handleBeginAttempt(
      attemptData.current,
      setAttemptData,
      Date.now(),
      missionSlug,
    );
  }, []);

  const endAttempt = useCallback(() => {
    handleEndAttempt(attemptData.current, setAttemptData, Date.now());
  }, []);

  const setAttemptAnswer = useCallback(
    (questionSlug, key, value) =>
      handleSetAttemptAnswer(
        setAttemptData,
        attemptData.current,
        questionSlug,
        key,
        value,
      ),
    [],
  );

  const clearAttempt = useCallback(() => {
    handleClearAttempt(setAttemptData, defaultAttemptData);
  }, []);

  return {
    getAttemptData,
    beginAttempt,
    setAttemptAnswer,
    clearAttempt,
  };
};

export default useAttemptManagement;
