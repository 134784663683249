import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    layout: {
      '& header': {
        marginBottom: 0,
        marginTop: 80,
      },
    },
  },
  { name: 'meta' },
);
