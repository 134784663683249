import { useCallback } from 'react';

const usePersistentStorage = (namespace, storageClass) => {
  const removeValue = useCallback(
    (key) => storageClass.remove(`${namespace}:${key}`),
    [namespace, storageClass],
  );

  const removeAllValues = useCallback(
    (key) => storageClass.removeAll(namespace),
    [namespace, storageClass],
  );

  const retrieveValue = useCallback(
    (key, defaultValue) =>
      storageClass.retrieve(`${namespace}:${key}`, defaultValue),
    [namespace, storageClass],
  );

  const storeValue = useCallback(
    (key, newValue) => storageClass.store(`${namespace}:${key}`, newValue),
    [namespace, storageClass],
  );

  return {
    removeAllValues,
    removeValue,
    retrieveValue,
    storeValue,
  };
};

export default usePersistentStorage;
