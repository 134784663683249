import { useContext } from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';
import Markup from '../../../Markup';
import Model from '../../../Model';
import Typography from '../../../Typography';
import useTileStyle from './hooks/useTileStyle';
import MissionIcon from './MissionIcon';
import useStyles from './missionTile.styles';
import TileBackground from './TileBackground';

const MissionTile = ({
  title,
  blurb,
  asset,
  iconAnimation,
  offset,
  dragApi,
  numMissions,
}) => {
  const { isDesktop } = useContext(MediaQueryContext);

  const typographyVariantPrefix = isDesktop ? '' : 'Mobile';
  const typographyTitleVariant = `carouselTileTitle${typographyVariantPrefix}`;
  const typographyBodyVariant = `carouselTileBody${typographyVariantPrefix}`;

  const { cornerRad, springStyles } = useTileStyle(
    offset,
    dragApi,
    numMissions,
  );

  const styles = useStyles();

  return (
    <animated.div className={styles.wrapper} style={springStyles}>
      <TileBackground corner={cornerRad} />
      <div className={styles.iconWrapper}>
        {asset && (
          <div className={styles.icon3DViewport}>
            <Model url={asset} />
          </div>
        )}
        {iconAnimation && !asset && (
          <MissionIcon
            iconAnimation={iconAnimation}
            shouldAnimate={offset === 0}
          />
        )}
      </div>
      <div className={styles.titleWrapper}>
        <Typography variant={typographyTitleVariant} withShadow>
          <Markup content={title} />
        </Typography>
      </div>
      <div className={styles.aboutWrapper}>
        <Typography variant={typographyBodyVariant} withShadow={false}>
          <Markup content={blurb} />
        </Typography>
      </div>
    </animated.div>
  );
};

MissionTile.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  asset: PropTypes.string,
  dragApi: PropTypes.shape({
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
  }).isRequired,
  iconAnimation: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      animationClass: PropTypes.string,
    }),
  ).isRequired,
  offset: PropTypes.number.isRequired,
  numMissions: PropTypes.number.isRequired,
};

MissionTile.defaultProps = {
  asset: null,
};

export default MissionTile;
