import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ToastContext } from '../../../contexts/toastContext';
import {
  handleAutoClose,
  handleMouseEnterLeave,
  handleOnClose,
  handleOnExited,
  handleOnMount,
} from '../logic';

const useToast = (id, isIn, duration, hasTopToast) => {
  const { closeToast, deregisterToast } = useContext(ToastContext);
  const [isHovered, setIsHovered] = useState(true);
  const closeTimerRef = useRef();

  const onClose = useCallback(
    () => handleOnClose(id, closeToast),
    [id, closeToast],
  );

  const onMouseEnter = useCallback(
    () => handleMouseEnterLeave(setIsHovered, true),
    [setIsHovered],
  );

  const onMouseLeave = useCallback(
    () => handleMouseEnterLeave(setIsHovered, false),
    [setIsHovered],
  );

  const onExited = useCallback(
    () => handleOnExited(id, deregisterToast, hasTopToast),
    [id, deregisterToast, hasTopToast],
  );

  const setCloseTimerRef = useCallback((ref) => {
    closeTimerRef.current = ref;
  }, []);

  useEffect(
    () =>
      handleAutoClose({
        isIn,
        isHovered,
        duration,
        onClose,
        setCloseTimerRef,
        closeTimerRef: closeTimerRef.current,
      }),
    [isIn, isHovered, duration, onClose],
  );

  useEffect(() => handleOnMount(setIsHovered, closeTimerRef), []);

  return {
    onClose,
    onMouseEnter,
    onMouseLeave,
    onExited,
    isHovered,
  };
};

export default useToast;
