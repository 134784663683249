import { useCallback } from 'react';

const usePlayerControls = (onSkip, stopPlaying) => {
  const onSkipButtonClick = useCallback(() => {
    stopPlaying();
    onSkip();
  }, [onSkip, stopPlaying]);

  return {
    onSkipButtonClick,
  };
};

export default usePlayerControls;
