import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    bodyWrapper: {
      maxWidth: 794,
      margin: [40, 'auto', 30],
      textAlign: 'center',
    },
    // videoMaxWrapper: {
    //   margin: [0, 'auto'],
    //   maxWidth: styling.breakpoints.values.xl,
    // },
    videoWrapper: {
      height: 0,
      position: 'relative',
      width: '100%',
      paddingBottom: 'min(100%, 900px)',
      [styling.breakpoints.up('md')]: {
        paddingBottom: `min(${(1080 / 1920) * 100}%, 900px)`,
      },
      '@media (min-width: 2000px)': {
        paddingBottom: `min(${(1080 / 1920) * 100}%, 1400px)`,
      },
      '&:before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height: 133,
        zIndex: 2,
        opacity: 0.8,
        background: `linear-gradient(180deg, ${styling.colors.theme.pureBlack} -20%, transparent 100%)`,
      },
      '&:after': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 2,
        opacity: 0.2,
        background: `radial-gradient(100% 100% at 50% 50%, transparent 0%, ${styling.colors.theme.pureBlack} 100%)`,
      },
    },
    videoInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      transition: styling.speeds.jog,
    },
    playerReady: {
      opacity: 1,
    },
  },
  { name: 'homeVideo' },
);
