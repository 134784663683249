import PropTypes from 'prop-types';

import {
  navTrayPrimary,
  navTraySecondary,
} from '../../../../services/constants/menus';
import IconButton from '../../../IconButton';
import InternalLink from '../../../InternalLink';
import Panel from '../../../Panel';
import Typography from '../../../Typography';
import useStyles from './mainNavPanel.styles';

const MainNavPanel = ({ isOpen }) => {
  const styles = useStyles();

  return (
    <Panel docked="right" isOpen={isOpen}>
      <div className={styles.wrapper}>
        <nav className={styles.primaryNav}>
          {navTrayPrimary().map((menuItem) => (
            <Typography
              key={menuItem.path}
              component={InternalLink}
              to={menuItem.path}
              variant="primaryNav"
              withHoverShadow
              withShadow
            >
              {menuItem.title}
            </Typography>
          ))}
        </nav>
        <nav className={styles.secondaryNav}>
          {navTraySecondary().map((menuItem) => (
            <Typography
              key={menuItem.path}
              component={InternalLink}
              to={menuItem.path}
              variant="secondaryNav"
              withHoverShadow
              withShadow
            >
              {menuItem.title}
            </Typography>
          ))}
        </nav>
      </div>
    </Panel>
  );
};

MainNavPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default MainNavPanel;
