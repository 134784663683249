import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      textAlign: 'center',
      paddingTop: 120,
      paddingBottom: 120,
    },
    title: {
      maxWidth: 911,
      margin: [0, 'auto'],
    },
    body: {
      maxWidth: 822,
      margin: [0, 'auto'],
      '& p + p': {
        marginTop: styling.sizes.gutteringSm,
      },
    },
    button: {
      minWidth: 200,
      width: 'auto',
      marginTop: 60,
      display: 'inline-block',
      paddingRight: 48,
      paddingLeft: 48,
    },
  },
  { name: 'metaAcknowledge' },
);
