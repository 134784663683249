export const desktopTileDimensions = {
  width: 484,
  height: 407,
  scale: 0.5448,
  cornerRad: 60,
  spacing: 35,
};

export const mobileTileDimensions = {
  width: 248,
  height: 322,
  scale: 1,
  cornerRad: 40,
  spacing: 35,
};
