import { createUseStyles } from 'react-jss';

import styling from '../../../../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      '& strong': {
        color: styling.colors.theme.primaryLight,
      },
    },
  },
  { name: 'questionSubtitle' },
);
