const routes = {
  meta: {
    path: '/',
    title: 'Home',
    priority: 1,
  },
  fourOhFour: {
    path: '*',
    title: '404',
    priority: 0,
  },
};

export default routes;
