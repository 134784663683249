import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './pyramid.styles';

const Pyramid = ({ position }) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.wrapper, styles[`position-${position}`])}>
      <div className={styles.pyramid} />
    </div>
  );
};

Pyramid.propTypes = {
  position: PropTypes.oneOf(['high', 'low']),
};

Pyramid.defaultProps = {
  position: 'low',
};

export default Pyramid;
