import PropTypes from 'prop-types';

import usePageTitle from './hooks/usePageTitle';

const PageTitle = ({ title, ...mergeProps }) => {
  usePageTitle(title, mergeProps);

  return null;
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default PageTitle;
