import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      marginTop: 'auto',
      padding: [36, 0, 33],
      position: 'relative',
      zIndex: 3,
      [styling.breakpoints.up('md')]: {
        padding: [52, 0, 33],
      },
      '& > button': {
        minWidth: 217,
      },
    },
  },
  { name: 'submitButton' },
);
