import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    button: {
      background: 'transparent',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
  },
  { name: 'iconButton' },
);
