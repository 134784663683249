import { useInView } from 'react-intersection-observer';

const useAnimatedNumber = () => {
  const { ref, inView } = useInView({
    fallbackInView: true,
    threshold: 0.3,
    triggerOnce: true,
  });

  return {
    ref,
    inView,
  };
};

export default useAnimatedNumber;
