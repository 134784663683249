import { useCallback, useEffect, useMemo, useRef } from 'react';
import lottie from 'lottie-web';

import { calculateStyle, handleLoadAnimation } from '../logic';

const useLottie = (animationData, width, height) => {
  const elementRef = useRef(null);
  const lottieInstance = useRef();

  const setLottieInstance = useCallback((ref) => {
    lottieInstance.current = ref;
  }, []);

  useEffect(
    () =>
      handleLoadAnimation({
        elementRef: elementRef.current,
        lottieInstance: lottieInstance.current,
        setLottieInstance,
        animationData,
        loadAnimation: lottie.loadAnimation,
      }),
    [animationData],
  );

  const style = useMemo(() => calculateStyle(width, height), [width, height]);

  return {
    elementRef,
    style,
  };
};

export default useLottie;
