import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      display: 'flex',
      bottom: 0,
      left: 0,
      right: 0,
      justifyContent: 'center',
      zIndex: 4,
      gap: 24,
      paddingBottom: 14,
      pointerEvents: 'none',
      '&:before': {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 210,
        background: `linear-gradient(0deg, ${styling.colors.theme.pureBlack} -10%, transparent 100%)`,
      },
    },
    button: {
      pointerEvents: 'all',
      background: 'none',
      border: 'none',
      padding: 6,
      lineHeight: 0,
      cursor: 'pointer',
    },
    buttonIcon: {
      filter: styling.shadows.utils.svgFilter(
        styling.shadows.theme.secondarySm,
      ),
    },
  },
  { name: 'playerControls' },
);
