import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      textAlign: 'center',
      paddingTop: 120,
      paddingBottom: 320,
    },
    title: {
      maxWidth: 911,
      margin: [0, 'auto'],
    },
    body: {
      maxWidth: 822,
      margin: [0, 'auto', 40],
      '& p + p': {
        marginTop: styling.sizes.gutteringSm,
      },
    },
    videoWrapper: {
      height: 0,
      position: 'relative',
      width: '100%',
      maxWidth: 646,
      margin: [0, 'auto'],
      paddingBottom: `min(${(9 / 16) * 100}%, 364px)`,
      border: [1, 'solid', styling.colors.theme.primaryLight],
      boxShadow: styling.shadows.theme.primaryLightSmFaded,
    },
    videoInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    button: {
      minWidth: 200,
      width: 'auto',
      marginTop: 60,
      display: 'inline-block',
      paddingRight: 48,
      paddingLeft: 48,
    },
  },
  { name: 'metaSupport' },
);
