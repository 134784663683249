import PropTypes from 'prop-types';

import styling from '../../../../../services/styling';
import useTileBackground from './hooks/useTileBackground';
import useStyles from './tileBackground.styles';

const TileBackground = ({ corner }) => {
  const { style } = useTileBackground(corner);
  const styles = useStyles();

  return <div className={styles.wrapper} style={style} />;
};

TileBackground.propTypes = {
  corner: PropTypes.number.isRequired,
};

export default TileBackground;
