import PropTypes from 'prop-types';

import Brand from './Brand';
import useStyles from './header.styles';

const Header = ({ noLink }) => {
  const styles = useStyles();
  return (
    <header className={styles.header}>
      <Brand noLink={noLink} />
    </header>
  );
};

Header.propTypes = {
  noLink: PropTypes.bool,
};

Header.defaultProps = {
  noLink: false,
};

export default Header;
