import { useCallback, useEffect, useState } from 'react';

import {
  getCloseToast,
  getDeregisterToast,
  getRegisterToast,
  handleToastsChange,
} from './logic';

const useToastManagement = () => {
  const [topToast, setTopToast] = useState();
  const [toasts, setToasts] = useState([]);

  const closeToast = useCallback(getCloseToast(setTopToast), [setTopToast]);

  const registerToast = useCallback(getRegisterToast(setToasts), [
    toasts,
    setToasts,
  ]);

  const deregisterToast = useCallback(getDeregisterToast(setToasts), [
    setToasts,
  ]);

  useEffect(
    () => handleToastsChange(toasts, setTopToast),
    [toasts, setTopToast],
  );

  return {
    registerToast,
    closeToast,
    deregisterToast,
    topToast,
    toasts,
  };
};

export default useToastManagement;
