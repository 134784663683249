const barlow = {
  fontFamily: "'Barlow', sans-serif",
  weights: {
    regular: 400,
    semiBold: 600,
  },
};

const barlowCondensed = {
  fontFamily: "'Barlow Condensed', sans-serif",
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
};

const ibmPlexMono = {
  fontFamily: "'IBM Plex Mono', monospace",
  weights: {
    semiBold: 600,
  },
};

const americanCaptain = {
  fontFamily: "american_captainregular, 'American Captain', sans-serif",
  weights: {
    regular: 400,
  },
};

export default {
  barlow,
  barlowCondensed,
  ibmPlexMono,
  americanCaptain,
};
