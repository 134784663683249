import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    sot: {
      position: 'absolute',
      top: 0,
      left: styling.sizes.gutteringSm,
      zIndex: styling.zIndex.panelToggle,
      marginTop: '6.8%',
      background: 'transparent',
      border: 'none',
      padding: [9, 9, 9, 0],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      [styling.breakpoints.up('sm')]: {
        marginTop: styling.sizes.gutteringLg - 10,
        left: styling.sizes.gutteringLg,
      },
      [styling.breakpoints.up('md')]: {
        padding: [9, 0, 0],
      },
      [styling.breakpoints.up('xl')]: {
        position: 'fixed',
      },
    },
    sotOpen: {},
    otherPanelOpen: {
      zIndex: 4,
    },
  },
  { name: 'sotNav' },
);
