import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const LoadWhenInView = ({
  children,
  className,
  fallbackInView,
  forceInView,
  triggerOnce,
  notInViewComponent,
}) => {
  const { ref, inView } = useInView({
    triggerOnce,
    fallbackInView,
  });

  return (
    <div className={className} ref={ref}>
      {inView || forceInView ? children : notInViewComponent}
    </div>
  );
};

LoadWhenInView.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fallbackInView: PropTypes.bool,
  triggerOnce: PropTypes.bool,
  notInViewComponent: PropTypes.node,
  forceInView: PropTypes.bool,
};

LoadWhenInView.defaultProps = {
  className: undefined,
  fallbackInView: true,
  triggerOnce: true,
  notInViewComponent: null,
  forceInView: false,
};

export default LoadWhenInView;
