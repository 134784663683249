import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../services/styling';
import Download from '../../Icons/Download';
import Typography from '../../Typography';
import useStyles from './downloadButton.styles';

const DownloadButton = ({ className, size, type, title }) => {
  const styles = useStyles();

  return (
    <Typography
      className={clsx(className, styles.wrapper)}
      variant="tileDownloadTitle"
      withShadow
    >
      <Download
        className={styles.download}
        color={styling.colors.theme.primaryLight}
      />
      {title || (
        <>
          Download{type && ` ${type}`}
          {size && ` (${size})`}
        </>
      )}
    </Typography>
  );
};

DownloadButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
};

DownloadButton.defaultProps = {
  className: undefined,
  size: undefined,
  type: undefined,
  title: undefined,
};

export default DownloadButton;
