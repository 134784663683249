import PropTypes from 'prop-types';

import routes from '../../../../services/constants/routes';
import InternalLink from '../../../InternalLink';
import useStyles from './brand.styles';

const Brand = ({ noLink }) => {
  const styles = useStyles();
  const Component = noLink ? 'div' : InternalLink;

  return (
    <Component to={routes.home.path}>
      <h1 className={styles.brand}>
        <span>The Conspiracy Test</span>
      </h1>
    </Component>
  );
};

Brand.propTypes = {
  noLink: PropTypes.bool,
};

Brand.defaultProps = {
  noLink: false,
};

export default Brand;
