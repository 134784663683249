import { parse, stringify } from 'telejson';

const storageIsAvailable = !!window.localStorage;
const storage = window.localStorage;

const storageFactory = () => {
  const retrieve = (key, defaultValue) => {
    if (!storageIsAvailable) {
      return defaultValue;
    }
    const storedValue = storage.getItem(key);
    if (storedValue !== null) {
      return parse(storedValue);
    }
    return defaultValue;
  };

  const store = (key, value) => {
    if (!storageIsAvailable) {
      return;
    }
    storage.setItem(key, stringify(value));
  };

  const remove = (key) => {
    if (!storageIsAvailable) {
      return;
    }
    storage.removeItem(key);
  };

  const removeAll = (namespace) => {
    if (!storageIsAvailable) {
      return;
    }
    const keys = Object.keys(storage);
    keys.forEach((key) => {
      if (key.indexOf(namespace) === 0) {
        storage.removeItem(key);
      }
    });
  };

  return {
    retrieve,
    store,
    remove,
    removeAll,
  };
};

export default storageFactory;
