import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    download: {
      marginRight: 10,
      filter: styling.shadows.utils.svgFilter(styling.shadows.theme.primarySm),
    },
  },
  { name: 'downloadButton' },
);
