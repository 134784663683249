import { upperFirst } from 'lodash';

import { htmlDecode } from '../../services/helpers/string';

const generateMatcher = (renderFn, matcherName) => ({
  inverseName: `no${upperFirst(matcherName)}`,
  propName: matcherName,
  match: (string) => {
    const result = string.match(new RegExp(`{{${matcherName}}}`));

    if (!result) {
      return null;
    }

    return {
      index: result.index,
      length: result[0].length,
      match: result[0],
      valid: true,
      void: true,
    };
  },
  createElement: (_, props) =>
    renderFn({ key: props.key }, `${htmlDecode(props[matcherName])}`),
  asTag: () => 'span', // this is ignored, but needs to be defined
});

export const generateMatchers = (renderFn, matcherNames = []) =>
  matcherNames.map((name) => generateMatcher(renderFn, name));
