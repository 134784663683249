import PropTypes from 'prop-types';
import clsx from 'clsx';

import useFormGrid from './hooks/useFormGrid';
import useStyles from './formGrid.styles';

const FormGrid = ({ children, item, widths }) => {
  const { style } = useFormGrid(widths);
  const styles = useStyles();

  return (
    <div
      className={clsx(!item && styles.wrapper, item && styles.item)}
      style={style}
    >
      {children}
    </div>
  );
};

FormGrid.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.bool,
  widths: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
};

FormGrid.defaultProps = {
  item: false,
  widths: undefined,
};

export default FormGrid;
