import { formStates } from '../constants';

export const getIsDisabled = (formState) =>
  formState === formStates.ERROR || formState === formStates.SUBMITTING;

export const handleOnClickCallback = (
  shouldReset,
  setFormState,
  clearFormData,
) => {
  setFormState(formStates.CLEAN);
  if (shouldReset === true) {
    clearFormData();
  }
};

export const handleOnClick = async ({
  onSubmit,
  formState,
  setFormState,
  getFormData,
  onClickCallback,
}) => {
  if (formState === formStates.CLEAN) {
    setFormState(formStates.SUBMITTING);
    try {
      await onSubmit(getFormData(), onClickCallback);
    } catch (e) {
      onClickCallback();
    }
  }
};
