import PropTypes from 'prop-types';

import useLocation from '../../../../hooks/useLocation';
import { footerMenu } from '../../../../services/constants/menus';
import routes from '../../../../services/constants/routes';
import InternalLink from '../../../InternalLink';
import SlantedButton from '../../../SlantedButton';
import useStyles from './footerMenu.styles';

const FooterMenu = ({ includeHrefPrefix }) => {
  const location = useLocation();
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {footerMenu().map((menuItem) => {
        if (menuItem.path === location.pathname) return null;
        return (
          <SlantedButton
            key={menuItem.path}
            className={styles.menuItem}
            component={includeHrefPrefix ? 'a' : InternalLink}
            to={includeHrefPrefix ? undefined : menuItem.path}
            href={
              includeHrefPrefix
                ? `https://theconspiracytest.org${menuItem.path}`
                : undefined
            }
            variant="primary"
          >
            {menuItem.title}
          </SlantedButton>
        );
      })}
      {includeHrefPrefix && (
        <SlantedButton
          className={styles.menuItem}
          component="a"
          href={`https://theconspiracytest.org${routes.legals.path}`}
          variant="primary"
        >
          {routes.legals.title}
        </SlantedButton>
      )}
    </div>
  );
};

FooterMenu.propTypes = {
  includeHrefPrefix: PropTypes.bool.isRequired,
};

export default FooterMenu;
