import { memo } from 'react';

import LayoutBrochure from '../../components/LayoutBrochure';
import { ErrorSpace } from '../../contexts/errorCaptureContext';
import { asyncRequestStates } from '../../hooks/useAsyncRequest';
import useGlobalContent from '../../hooks/useGlobalContent';
import useMetaPageContent from './hooks/useMetaPageContent';
import useStyles from './meta.styles';
import MetaAcknowledge from './MetaAcknowledge';
import MetaHeader from './MetaHeader';
import MetaMissionSelector from './MetaMissionSelector';
import MetaSupport from './MetaSupport';

const Meta = () => {
  const styles = useStyles();
  const {
    content: { missions },
    contentState,
  } = useGlobalContent();
  const {
    pageContent: {
      header,
      sendPostersForm,
      missionsCarousel,
      acknowledge,
      support,
    },
    pageContentState,
  } = useMetaPageContent();
  return (
    <>
      {pageContentState === asyncRequestStates.SUCCESS && (
        <MetaHeader content={header} formContent={sendPostersForm} />
      )}
      <LayoutBrochure
        className={styles.layout}
        noMainNav
        noSOTNav
        includeHrefPrefix
      >
        <ErrorSpace space="/meta">
          {pageContentState === asyncRequestStates.SUCCESS &&
            contentState === asyncRequestStates.SUCCESS && (
              <>
                <MetaMissionSelector
                  subtitle={missionsCarousel?.title}
                  missions={missions}
                />
                <MetaAcknowledge content={acknowledge} />
                <MetaSupport content={support} />
              </>
            )}
        </ErrorSpace>
      </LayoutBrochure>
    </>
  );
};

export default memo(Meta);
