import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Animate } from 'react-move';

import styling from '../../../../services/styling';
import useStatWheel from './hooks/useStatWheel';
import { CIRCLE_PADDING, CIRCLE_RADIUS, CIRCLE_THICKNESS } from './constants';
import useStyles from './statWheel.styles';

const StatWheel = ({ children, secondaryValue, type, value }) => {
  const center = CIRCLE_RADIUS + CIRCLE_THICKNESS + CIRCLE_PADDING;
  const styles = useStyles();
  const {
    ref,
    inView,
    mainStrokeProps,
    secondaryStrokeProps,
    mainStrokeSize,
    secondaryStrokeSize,
  } = useStatWheel(type, value, secondaryValue);

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.graph}>
        <svg className={styles.svg} viewBox={`0 0 ${center * 2} ${center * 2}`}>
          <circle
            cx={center}
            cy={center}
            fill="none"
            r={CIRCLE_RADIUS}
            stroke={styling.colors.theme.pureWhiteFaded}
            strokeWidth={CIRCLE_THICKNESS}
          />
          <Animate
            start={{
              mainDashArrayStart: 0,
              secondaryDashArrayStart: 0,
            }}
            update={{
              mainDashArrayStart: [inView ? mainStrokeSize.dasharrayStart : 0],
              secondaryDashArrayStart: [
                inView ? secondaryStrokeSize.dasharrayStart : 0,
              ],
              timing: { duration: parseInt(styling.speeds.walk, 10) },
            }}
          >
            {({ mainDashArrayStart, secondaryDashArrayStart }) => (
              <>
                <circle
                  className={clsx(styles.line, styles.primaryLine)}
                  cx={center}
                  cy={center}
                  fill="none"
                  r={CIRCLE_RADIUS}
                  strokeDasharray={`${mainDashArrayStart}, ${mainStrokeSize.dasharrayEnd}`}
                  {...mainStrokeProps}
                  strokeWidth={CIRCLE_THICKNESS}
                />
                {secondaryValue && (
                  <circle
                    className={clsx(styles.line, styles.secondaryLine)}
                    cx={center}
                    cy={center}
                    fill="none"
                    r={CIRCLE_RADIUS}
                    strokeDasharray={`${secondaryDashArrayStart}, ${secondaryStrokeSize.dasharrayEnd}`}
                    {...secondaryStrokeProps}
                    strokeLinecap="round"
                    strokeWidth={CIRCLE_THICKNESS}
                  />
                )}
              </>
            )}
          </Animate>
        </svg>
        <div className={styles.children}>{inView ? children : null}</div>
      </div>
    </div>
  );
};

StatWheel.propTypes = {
  children: PropTypes.node.isRequired,
  secondaryValue: PropTypes.number,
  type: PropTypes.oneOf(['score', 'increase', 'baseline']).isRequired,
  value: PropTypes.number.isRequired,
};

StatWheel.defaultProps = {
  secondaryValue: undefined,
};

export default StatWheel;
