import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Check = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 19 16"
    width={width}
  >
    <polyline
      points=".75 8.96 6.39 14.46 18.26 .76"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
  </IconSvg>
);

Check.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Check.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 16,
  width: 19,
};

export default Check;
