import { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { MediaQueryContext } from '../../../contexts/mediaQueryContext';
import Markup from '../../Markup';
import Typography from '../../Typography';
import usePlayerCaptions from './hooks/usePlayerCaptions';
import useStyles from './playerCaptions.styles';

const PlayerCaptions = ({ active, playerRef }) => {
  const { isDesktop } = useContext(MediaQueryContext);
  const { caption } = usePlayerCaptions(playerRef);
  const styles = useStyles();

  return (
    <Typography
      className={clsx(styles.wrapper, active && styles.active)}
      variant={`videoCaption${isDesktop ? '' : 'Mobile'}`}
      withShadow
    >
      <Markup content={caption} />
    </Typography>
  );
};

PlayerCaptions.propTypes = {
  active: PropTypes.bool.isRequired,
  playerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

PlayerCaptions.defaultProps = {
  playerRef: null,
};

export default PlayerCaptions;
