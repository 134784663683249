import { useEffect, useRef, useState } from 'react';

export const asyncRequestStates = {
  DEFAULT: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const useAsyncRequest = (fn, successCallback) => {
  const mountState = useRef(false);

  useEffect(() => {
    mountState.current = true;

    return () => {
      mountState.current = false;
    };
  }, []);

  const runWhenMounted = (fnToRun) => {
    if (mountState.current) {
      fnToRun();
    }
  };

  const [state, setState] = useState(asyncRequestStates.DEFAULT);
  const [error, setError] = useState();

  const run = async (...args) => {
    try {
      runWhenMounted(() => setState(asyncRequestStates.LOADING));

      const result = await fn(...args);

      runWhenMounted(() => {
        successCallback(result);
        setState(asyncRequestStates.SUCCESS);
        setError();
      });

      return result;
    } catch (e) {
      runWhenMounted(() => {
        setState(asyncRequestStates.ERROR);
        setError(e);
      });
      throw e;
    }
  };

  return [run, state, error];
};

export default useAsyncRequest;
