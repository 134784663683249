import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const ClosedCaptions = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="currentColor">
      <path d="M26.449,3.461 C28.372,3.463 29.93,5.021 29.932,6.944 L29.932,6.944 L29.932,25.056 C29.93,26.979 28.372,28.537 26.449,28.539 L26.449,28.539 L5.551,28.539 C3.628,28.537 2.07,26.979 2.068,25.056 L2.068,25.056 L2.068,6.944 C2.07,5.021 3.628,3.463 5.551,3.461 L5.551,3.461 Z M26.449,4.855 L5.551,4.855 C4.397,4.856 3.462,5.791 3.461,6.944 L3.461,6.944 L3.461,25.056 C3.462,26.209 4.397,27.144 5.551,27.146 L5.551,27.146 L26.449,27.146 C27.602,27.144 28.538,26.209 28.539,25.056 L28.539,25.056 L28.539,6.944 C28.537,5.791 27.602,4.856 26.449,4.855 L26.449,4.855 Z M11.8202,10.4276 C13.3592,10.4276 14.6072,11.6746 14.6072,13.2136 C14.6072,13.5986 14.2952,13.9106 13.9102,13.9106 C13.5252,13.9106 13.2132,13.5986 13.2132,13.2136 C13.2132,12.4446 12.5902,11.8206 11.8202,11.8206 C11.0512,11.8206 10.4272,12.4446 10.4272,13.2136 L10.4272,13.2136 L10.4272,18.7866 C10.4272,19.5556 11.0502,20.1796 11.8202,20.1796 C12.5902,20.1796 13.2132,19.5556 13.2132,18.7866 C13.2132,18.4016 13.5252,18.0896 13.9102,18.0896 C14.2952,18.0896 14.6072,18.4016 14.6072,18.7866 C14.6072,20.3256 13.3592,21.5726 11.8202,21.5726 C10.2812,21.5726 9.0342,20.3256 9.0342,18.7866 L9.0342,18.7866 L9.0342,13.2136 C9.0342,11.6746 10.2812,10.4276 11.8202,10.4276 Z M20.1796,10.4276 C21.7186,10.4276 22.9656,11.6746 22.9656,13.2136 C22.9656,13.5986 22.6536,13.9106 22.2696,13.9106 C21.8846,13.9106 21.5726,13.5986 21.5726,13.2136 C21.5726,12.4446 20.9486,11.8206 20.1796,11.8206 C19.4106,11.8206 18.7866,12.4446 18.7856,13.2136 L18.7856,13.2136 L18.7856,18.7866 C18.7856,19.5556 19.4096,20.1796 20.1796,20.1796 C20.9486,20.1796 21.5726,19.5556 21.5726,18.7866 C21.5726,18.4016 21.8846,18.0896 22.2696,18.0896 C22.6536,18.0896 22.9656,18.4016 22.9656,18.7866 C22.9656,20.3256 21.7186,21.5726 20.1796,21.5726 C18.6406,21.5726 17.3926,20.3256 17.3926,18.7866 L17.3926,18.7866 L17.3926,13.2136 C17.3926,11.6746 18.6406,10.4276 20.1796,10.4276 Z" />
    </g>
  </IconSvg>
);

ClosedCaptions.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ClosedCaptions.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default ClosedCaptions;
