import React from 'react';

import LoadingOverlay from '../../components/LoadingOverlay';
import { ErrorBoundary } from '../../contexts/errorCaptureContext';
import FourOhFour from '../../scenes/404';
import routes from './routes';

const About = React.lazy(() => import('../../scenes/about'));
const Contact = React.lazy(() => import('../../scenes/contact'));
const Faqs = React.lazy(() => import('../../scenes/faqs'));
const Home = React.lazy(() => import('../../scenes/home'));
const Legals = React.lazy(() => import('../../scenes/legals'));
const Media = React.lazy(() => import('../../scenes/media'));
const Mission = React.lazy(() => import('../../scenes/mission'));
const MissionIndex = React.lazy(() =>
  import('../../scenes/mission/scenes/index'),
);
const MissionSelected = React.lazy(() =>
  import('../../scenes/mission/scenes/missionSelected'),
);
const UserShare = React.lazy(() =>
  import('../../scenes/mission/scenes/userShare'),
);
const UserShareShort = React.lazy(() =>
  import('../../scenes/mission/scenes/userShareShort'),
);
const Share = React.lazy(() => import('../../scenes/share'));
const Stats = React.lazy(() => import('../../scenes/stats'));

const routeElementMap = {
  contact: Contact,
  home: Home,
  about: About,
  media: Media,
  share: Share,
  legals: Legals,
  legalsTab: Legals,
  missionSelected: MissionSelected,
  userShare: UserShare,
  userShareShort: UserShareShort,
  mission: Mission,
  missionIndex: MissionIndex,
  stats: Stats,
  faqs: Faqs,
  fourOhFour: FourOhFour,
};

const NoView = () => null;

const getComponentForRouteKey = (routeKey, title) => {
  if (routeElementMap[routeKey]) {
    const Component = routeElementMap[routeKey];
    return (
      <React.Suspense fallback={<LoadingOverlay />}>
        <Component title={title} />
      </React.Suspense>
    );
  }
  return <NoView />;
};

const processRoutes = (routesObject) =>
  Object.keys(routesObject).map((routeKey) => {
    const { childRoutes, title } = routesObject[routeKey];

    return {
      ...routesObject[routeKey],
      children: childRoutes ? processRoutes(childRoutes) : undefined,
      element: getComponentForRouteKey(routeKey, title),
      ErrorBoundary,
    };
  });

const routesWithViews = processRoutes(routes);

export default routesWithViews;
