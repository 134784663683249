import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useAlertManagement from '../hooks/useAlertManagement';

export const AlertContext = createContext({
  closeAlert: () => {},
  topAlert: undefined,
  alerts: [],
  registerAlert: () => {},
  deregisterAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const value = useAlertManagement();

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
