import { useMemo } from 'react';

import { calculateScore } from '../../../services/helpers/calculateScore';

const useStatsDisplay = ({ baseline = 0, increase = 0 }) => {
  const score = useMemo(
    () => calculateScore(baseline, increase),
    [baseline, increase],
  );

  return {
    score,
  };
};

export default useStatsDisplay;
