import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { AnalyticsContext } from '../../../contexts/analyticsContext';
import { handleTrackPageTitleEvent, onSetPageTitle } from '../logic';

const usePageTitle = (title, mergeProps) => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState();
  const { trackEvent } = useContext(AnalyticsContext);

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
      handleTrackPageTitleEvent(trackEvent, pageTitle, location.pathname);
    }
  }, [pageTitle]);

  useEffect(
    () => onSetPageTitle(setPageTitle, title, mergeProps),
    [setPageTitle, title, mergeProps],
  );
};

export default usePageTitle;
