import { useCallback, useMemo, useState } from 'react';

import { getEnhancedLabel, getInputProps } from '../logic';

const useField = ({ formState, label, validation }) => {
  const enhancedLabel = useMemo(
    () => getEnhancedLabel(label, validation),
    [label, validation],
  );

  const inputProps = useMemo(
    () => getInputProps(formState, validation),
    [formState, validation],
  );

  return {
    inputProps,
    enhancedLabel,
  };
};

export default useField;
