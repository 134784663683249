import { useMemo } from 'react';

import { getMatcherNamesFromMergeValues } from '../logic';

const useQuestionSubtitle = (markupMergeValues) => {
  const matcherNames = useMemo(
    () => getMatcherNamesFromMergeValues(markupMergeValues),
    [markupMergeValues],
  );

  return {
    matcherNames,
  };
};

export default useQuestionSubtitle;
