import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCombobox } from 'downshift';

import {
  getFilteredOptions,
  getItemToString,
  getSanitizedItem,
  handleGetFilterInputProps,
  handleIsOpenChange,
  handleOnChange,
  handleOnFilterChange,
} from '../logic';

const useInputTypeahead = ({
  options,
  onChange,
  value,
  meta,
  isFocused,
  onFocus,
}) => {
  const dropdownListRef = useRef();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectedItem = useMemo(
    () => getSanitizedItem(options, value),
    [options, value],
  );

  const onFilterOptions = useCallback(
    (inputValue) => getFilteredOptions(options, inputValue.toLowerCase()),
    [options],
  );

  const onInputValueChange = useCallback(
    ({ inputValue }) => {
      setFilteredOptions(onFilterOptions(inputValue));
    },
    [onFilterOptions, setFilteredOptions],
  );

  const stateReducer = useCallback(
    (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ToggleButtonClick: {
          if (!isFocused && changes.isOpen) {
            window.setTimeout(() => {
              onFocus();
            }, 0);
          }
          return changes;
        }
        case useCombobox.stateChangeTypes.InputChange: {
          if (changes.inputValue.length === 1 && changes.isOpen) {
            dropdownListRef?.current?.scrollTo(0, 0);
          }
          return changes;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape: {
          dropdownListRef?.current?.scrollTo(0, 0);
          return changes;
        }
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            ...(changes.selectedItem && {
              inputValue: '',
            }),
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
            selectedItem,
          };
        default:
          return changes; // otherwise business as usual.
      }
    },
    [selectedItem, onFocus, dropdownListRef.current],
  );

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    inputValue,
  } = useCombobox({
    // isOpen: isFocused,
    items: filteredOptions,
    itemToString: getItemToString,
    onInputValueChange,
    selectedItem: selectedItem || null,
    onSelectedItemChange: handleOnChange(onChange),
    stateReducer,
  });

  const getFilterInputProps = useCallback(
    () => handleGetFilterInputProps(meta),
    [meta],
  );

  // useEffect(() => handleIsOpenChange(isOpen, setFilterValue), [isOpen]);

  return {
    dropdownListRef,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    filteredOptions,
    getFilterInputProps,
    getInputProps,
  };
};

export default useInputTypeahead;
