import { useCallback, useContext, useEffect, useState } from 'react';

import { AlertContext } from '../../contexts/alertContext';
import { RequestsContext } from '../../contexts/requestsContext';
import useAsyncRequest from '../useAsyncRequest';
import {
  getInviteGuid,
  handleCheckForBadInviteParams,
  handleOnBadInviteDetails,
  handleOnGoodInviteDetails,
} from './logic';

const useInviteManagement = () => {
  const { registerAlert, closeAlert } = useContext(AlertContext);
  const [invitedUser, setInvitedUser] = useState();
  const [performRequest] = useContext(RequestsContext);

  const onBadInviteDetails = useCallback(() => handleOnBadInviteDetails(), []);

  const onGoodInviteDetails = useCallback(
    (userDetails) => handleOnGoodInviteDetails(userDetails, registerAlert),
    [registerAlert],
  );

  const [fetchContent, fetchState] = useAsyncRequest(
    (inviteGuid) =>
      performRequest({
        method: 'GET',
        // url: `/data/bouncer/${inviteGuid}.json`,
        url: `/beta/bouncer/${inviteGuid}`,
      }),
    (newContent) => {
      setInvitedUser(newContent);
      onGoodInviteDetails(newContent);
    },
  );

  const onGetInviteDetails = useCallback(async () => {
    const inviteGuid = getInviteGuid(window.location.search);
    if (!inviteGuid) {
      onBadInviteDetails();
    }
    try {
      await fetchContent(inviteGuid);
    } catch (e) {
      onBadInviteDetails();
    }
  }, [fetchContent]);

  const onCheckForBadInviteParams = useCallback(
    () => handleCheckForBadInviteParams(window.location.search, registerAlert),
    [registerAlert],
  );

  useEffect(() => {
    if (process.env.REACT_APP_RELEASE_SPACE === 'beta') {
      onGetInviteDetails();
    } else if (process.env.REACT_APP_RELEASE_SPACE === 'landing') {
      onCheckForBadInviteParams();
    }
  }, []);

  return {
    contentState: fetchState,
    invitedUser,
  };
};

export default useInviteManagement;
