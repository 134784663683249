// Wait for the recaptcha object to become available
// The promise will resolve as soon as needed, keep resolving with the

import log from '../../../services/log';
import { defaultCaptchaConfig } from './constants';

// cached object.
const waitForRecaptcha = () =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (typeof window?.grecaptcha?.render === 'function') {
        clearInterval(interval);
        resolve(window.grecaptcha);
      }
    }, 1000);
  });

// sus on if we need this or we can use a ref for the element
const waitForElement = (elementID) =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && document.getElementById(elementID)) {
        clearInterval(interval);
        resolve(document.getElementById(elementID));
      }
    }, 1000);
  });

// script will check if a script with the url already exists before attempting to add it
// this prevents the script being loaded multiple times
const loadScript = (url) => {
  if (
    !Array.from(document.querySelectorAll('script')).some(
      (elm) => elm.src === url,
    )
  ) {
    const script = document.createElement('script');
    script.setAttribute('src', url);
    script.setAttribute('async', 'async');
    script.setAttribute('defer', 'defer');
    document.getElementsByTagName('head')[0].appendChild(script);
  }
};

export const handleOnMount = async ({
  elementID,
  scriptUrl,
  setRecaptchaRef,
  sitekey,
  setWidgetRef,
  onExpiredCallback,
  onVerifyCallback,
  setIsLoading,
}) => {
  loadScript(scriptUrl);

  const grecaptcha = await waitForRecaptcha();
  if (!grecaptcha) return;

  const element = await waitForElement(elementID);
  if (!element) return;

  log.debug('[RECAPTCHA] rendering captcha on element');

  setRecaptchaRef(grecaptcha);
  const widget = grecaptcha.render(element, {
    ...defaultCaptchaConfig,
    sitekey,
    callback: onVerifyCallback,
    'expired-callback': onExpiredCallback,
  });
  setWidgetRef(widget);

  log.debug('recaptcha loaded');
  setIsLoading(false);

  // TODO: loading state on form until captcha loaded
  // if (onloadCallback) {
  //   onloadCallback(widget);
  // }
};

export const handleOnExpiredCallback = (recaptchaRef, widgetRef) => {
  if (recaptchaRef && widgetRef !== null) {
    recaptchaRef?.reset(widgetRef);
    log.debug('[RECAPTCHA] expired, resetting');
  }
};

export const handleOnVerifyCallback = (
  response,
  onVerify,
  recaptchaRef,
  widgetRef,
) => {
  onVerify(response);
  log.debug('[RECAPTCHA] response', response);
  if (recaptchaRef && widgetRef !== null) {
    // TODO: move this to after submission success/fail?
    recaptchaRef?.reset(widgetRef);
    log.debug('[RECAPTCHA] completed resetting');
  }
};

export const handleOnExecuteCaptcha = (recaptchaRef, widgetRef) => {
  if (recaptchaRef && widgetRef !== null) {
    recaptchaRef?.execute(widgetRef);
  } else {
    throw new Error('[RECAPTCHA] Instance not found');
  }
};
