import { useCallback, useContext, useEffect } from 'react';

import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';
import { PanelContext } from '../../../../contexts/panelContext';
import ArrowHeadWithTimesIcon from '../ArrowHeadWithTimesIcon';
import { getToggleSOTNavClick, PANEL_ID } from '../logic';
import SOTFullWithArrowHeadIcon from '../SOTFullWithArrowHeadIcon';
import SOTNavPanel from '../SOTNavPanel';

const useSOTNav = (isResponsive) => {
  const { openPanel, closePanel, openedPanel, registerPanel, deregisterPanel } =
    useContext(PanelContext);
  const { isDesktop } = useContext(MediaQueryContext);

  useEffect(() => {
    registerPanel(PANEL_ID, SOTNavPanel);
    return () => deregisterPanel(PANEL_ID);
  }, []);

  const isOpen = openedPanel === PANEL_ID;
  const otherPanelOpen = !!openedPanel && !isOpen;

  const toggleSOTNav = useCallback(
    getToggleSOTNavClick(openPanel, isOpen, closePanel),
    [openPanel, closePanel, isOpen],
  );

  const SOTIcon =
    isDesktop || !isResponsive
      ? SOTFullWithArrowHeadIcon
      : ArrowHeadWithTimesIcon;

  return { toggleSOTNav, SOTIcon, isOpen, otherPanelOpen };
};

export default useSOTNav;
