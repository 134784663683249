import PropTypes from 'prop-types';
import clsx from 'clsx';

import ArrowHeadDown from '../../../Icons/ArrowHeadDown';
import SOTFull from '../../../Icons/SOTFull';
import useStyles from './sOTFullWithArrowHeadDownIcon.styles';

const SOTFullWithArrowHeadDownIcon = ({ isOpen, color }) => {
  const styles = useStyles();
  return (
    <>
      <SOTFull height={39} width={199} color={isOpen ? undefined : color} />
      <ArrowHeadDown
        className={clsx(styles.arrowHead, isOpen && styles.arrowHeadOpen)}
        color={isOpen ? undefined : color}
      />
    </>
  );
};

SOTFullWithArrowHeadDownIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

export default SOTFullWithArrowHeadDownIcon;
