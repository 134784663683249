import { isRequired } from '../../../services/helpers/validators';
import { formStates } from '../constants';

export const getEnhancedLabel = (label, validation) => {
  if (label && validation.includes(isRequired)) {
    return `${label}*`;
  }
  return label;
};

export const getInputProps = (formState, validation) => {
  let inputProps = {};
  if (validation.includes(isRequired)) {
    inputProps = {
      ...inputProps,
      required: true,
    };
  }
  if (formState === formStates.SUBMITTING) {
    inputProps = {
      ...inputProps,
      disabled: true,
    };
  }
  return inputProps;
};
