import { useCallback, useContext } from 'react';

import { RequestsContext } from '../../../../../../contexts/requestsContext';
import { ToastContext } from '../../../../../../contexts/toastContext';
import useAsyncRequest, {
  asyncRequestStates,
} from '../../../../../../hooks/useAsyncRequest';
import { prepareRequestPayload } from './logic';

const useSendPostersModal = (onClose) => {
  const { registerToast } = useContext(ToastContext);
  const [performRequest] = useContext(RequestsContext);
  const [submitAttemptRequest, attemptRequestState] = useAsyncRequest(
    (payload) =>
      performRequest({
        method: 'POST',
        url: '/send-posters',
        data: payload,
      }),
    () => {
      registerToast('send-posters', {
        variant: 'info',
        duration: 2000,
        title: 'Sent!',
        body: 'Check your inbox for an email from School of Thought.',
      });
      onClose();
    },
  );

  const onSubmit = useCallback(async (formData, callback) => {
    const requestPayload = prepareRequestPayload(formData);
    try {
      await submitAttemptRequest(requestPayload);
      callback(true);
    } catch (e) {
      callback();
    }
  }, []);

  return {
    onSubmit,
    isSubmitting: attemptRequestState === asyncRequestStates.LOADING,
  };
};

export default useSendPostersModal;
