const wrapTitleFilterText = (title, filterValue) =>
  title.replace(new RegExp(filterValue, 'ig'), '<b>$&</b>');

export const getSanitizedItem = (options, value) =>
  options.find((option) => option.value === value);

export const getItemToString = (item) => item?.title || '';

export const handleOnChange =
  (onChange) =>
  ({ selectedItem }) =>
    onChange(selectedItem?.value);

export const getFilteredOptions = (options, filterValue) => {
  if (!filterValue) return options;

  return options.reduce((acc, option) => {
    if (option.title.toLowerCase().indexOf(filterValue) >= 0) {
      return acc.concat({
        ...option,
        title: wrapTitleFilterText(option.title, filterValue),
      });
    }
    return acc;
  }, []);
};

export const handleOnFilterChange = (filterValue, setValue) => {
  setValue(filterValue);
};

export const handleGetFilterInputProps = (meta) => ({
  placeholder: meta?.filterPlaceholder,
});

export const handleIsOpenChange = (isOpen, setFilterValue) => {
  if (!isOpen) {
    setFilterValue('');
  }
};
