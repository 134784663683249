import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    routeWrapper: {
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        width: '100%',
      },
    },
  },
  { name: 'globalFetcher' },
);
