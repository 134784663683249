import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

const sharedScoreWheelStyles = {
  marginTop: 20,
  flexBasis: '100%',
  flexDirection: 'column-reverse',
  '& > div': {
    maxWidth: 270,
  },
  '& $wheelTitle': {
    marginTop: 0,
    marginBottom: -10,
  },
};

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      maxWidth: 820,
      margin: [0, 'auto'],
      justifyContent: 'center',
      flexWrap: 'wrap',
      rowGap: 20,
      columnGap: 0,
      position: 'relative',
      [styling.breakpoints.up('md')]: {
        columnGap: 90,
      },
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    },
    score: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      order: 0,
      width: '100%',
      [styling.breakpoints.up('md')]: {
        order: 1,
        width: 'unset',
      },
    },
    scoreNumberWrapper: {
      width: '100%',
      maxWidth: 200,
      margin: [0, 'auto'],
    },
    scoreNumber: {
      width: '100%',
      height: 0,
      paddingBottom: '100%',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 0,
        bottom: 0,
        opacity: 0.5,
        background: `radial-gradient(circle, ${styling.colors.theme.pureBlack} 0%, transparent 60%)`,
        width: '200%',
        height: '200%',
        transform: 'translate(-50%, -50%)',
        zIndex: -1,
        [styling.breakpoints.up('md')]: {
          content: '""',
        },
      },
      '& > *': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 162,
        textAlign: 'center',
        display: 'block',
        '&:before,&:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          height: 2,
          width: 60,
        },
        '&:before': {
          left: 0,
          background: `linear-gradient(90deg, ${styling.colors.theme.primary} 0%, transparent 100%)`,
          transform: 'translate(calc(-100% - 10px), -50%)',
        },
        '&:after': {
          right: 0,
          background: `linear-gradient(270deg, ${styling.colors.theme.primary} 0%, transparent 100%)`,
          transform: 'translate(calc(100% + 10px), -50%)',
        },
      },
    },
    scoreTitle: {
      maxWidth: 186,
      margin: [0, 'auto'],
      textShadow: styling.shadows.theme.primaryLg,
      transition: styling.speeds.jog,
      '& > span': {
        color: styling.colors.theme.primaryLight,
      },
    },
    wheel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      '&:last-child': {
        order: 2,
      },
      [styling.breakpoints.up('md')]: {
        width: 220,
      },
    },
    wheelTitle: {
      width: '100%',
      textShadow: styling.shadows.theme.primaryLg,
      transition: styling.speeds.jog,
      '& > span': {
        color: styling.colors.theme.primaryLight,
      },
      [styling.breakpoints.up('md')]: {
        marginTop: -10,
      },
    },
    wheelTitleSecondary: {
      textShadow: styling.shadows.theme.secondaryLg,
      transition: styling.speeds.jog,
      '& > span': {
        color: styling.colors.theme.secondary,
      },
    },
    stacked: {
      '&$wrapper': {
        columnGap: 0,
      },
      '& $score': {
        order: 0,
        width: '100%',
      },
      '& $wheel': {
        width: '50%',
      },
    },
    noData: {
      '& $scoreTitle, & $wheelTitle, & $wheelTitleSecondary': {
        textShadow: styling.shadows.theme.transparent,
        '& > span': {
          color: 'inherit',
        },
      },
    },
  },
  { name: 'statsDisplayWheels' },
);
