import PropTypes from 'prop-types';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const VolumeOff = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 32 32"
    width={width}
  >
    <g fill="none" stroke="currentColor">
      <path d="M19.75005,3 L19.75005,29 L7.75005,22 L7.75005,21.9999 L2.75005,21.9999 L2.75005,10.9999 L7.75005,10.9999 L7.75005,11 L19.75005,3 Z" />
    </g>
  </IconSvg>
);

VolumeOff.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

VolumeOff.defaultProps = {
  color: styling.colors.theme.secondaryDark,
  height: 32,
  width: 32,
};

export default VolumeOff;
