import { useContext } from 'react';

import { MediaQueryContext } from '../../../../../contexts/mediaQueryContext';
import { desktopTileDimensions, mobileTileDimensions } from '../constants';

const useTileDimensions = () => {
  const { isDesktop } = useContext(MediaQueryContext);
  return isDesktop ? desktopTileDimensions : mobileTileDimensions;
};

export default useTileDimensions;
