import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import pyramid from '../../../static/images/pyramid-fade.webp';

export default createUseStyles(
  {
    '@keyframes pyramidFloat': {
      '100%,0%': {
        transform: `
					translate(0px, 0)
				`,
      },
      '50%': {
        transform: `
					translate(0px, 8px)
				`,
      },
    },
    wrapper: {
      position: 'absolute',
      left: 0,
      overflow: 'hidden',
      height: 445,
      width: 350,
      display: 'none',
      pointerEvents: 'none',
      zIndex: 2,
      [styling.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    'position-high': {
      top: 220,
    },
    'position-low': {
      top: 450,
    },
    pyramid: {
      position: 'absolute',
      right: 0,
      top: 0,
      backgroundImage: `url(${pyramid})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 551,
      height: 437,
      animationName: '$pyramidFloat',
      animationIterationCount: 'infinite',
      animationDuration: '5s',
      animationTimingFunction: 'ease-in-out',
    },
  },
  { name: 'pyramid' },
);
