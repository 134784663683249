import PropTypes from 'prop-types';
import clsx from 'clsx';

import AnimatedNumber from '../../AnimatedNumber';
import LoadingOverlay from '../../LoadingOverlay';
import Typography from '../../Typography';
import useStyles from './statsDisplayWheels.styles';
import StatWheel from './StatWheel';

const StatsDisplayWheels = ({
  includeScore,
  data,
  score,
  isStacked,
  isLoading,
}) => {
  const styles = useStyles();
  const noData = data?.baseline === 0 && data?.increase === 0;

  return (
    <div
      className={clsx(
        styles.wrapper,
        isStacked && styles.stacked,
        noData && styles.noData,
      )}
    >
      {isLoading && (
        <LoadingOverlay className={styles.loadingOverlay} hasBackground />
      )}
      {includeScore && (
        <div className={styles.score}>
          <div className={styles.scoreNumberWrapper}>
            <div className={styles.scoreNumber}>
              <AnimatedNumber value={score} variant="statWheelLg" />
            </div>
          </div>
          <Typography className={styles.scoreTitle} variant="statDisplayTitle">
            <span>Average</span> Critical Thinking Score
          </Typography>
        </div>
      )}
      <div className={styles.wheel}>
        <StatWheel type="baseline" value={data?.baseline}>
          <AnimatedNumber
            append="%"
            value={data?.baseline}
            variant="statWheelSecondary"
          />
        </StatWheel>
        <Typography
          className={clsx(styles.wheelTitle, styles.wheelTitleSecondary)}
          variant="statDisplayTitle"
        >
          <span>Baseline</span> Skepticism
        </Typography>
      </div>
      <div className={styles.wheel}>
        <StatWheel
          secondaryValue={data?.increase}
          type="increase"
          value={data?.baseline}
        >
          <AnimatedNumber
            append="%"
            prepend="+"
            value={data?.increase}
            variant="statWheelPrimary"
          />
        </StatWheel>
        <Typography className={styles.wheelTitle} variant="statDisplayTitle">
          <span>Increase</span> in Skepticism
        </Typography>
      </div>
    </div>
  );
};

StatsDisplayWheels.propTypes = {
  includeScore: PropTypes.bool,
  isStacked: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    increase: PropTypes.number.isRequired,
    baseline: PropTypes.number.isRequired,
  }),
  score: PropTypes.number,
  isLoading: PropTypes.bool,
};

StatsDisplayWheels.defaultProps = {
  includeScore: true,
  isStacked: false,
  score: 0,
  data: {
    increase: 0,
    baseline: 0,
  },
  isLoading: false,
};

export default StatsDisplayWheels;
