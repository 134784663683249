import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    '@keyframes filterFadeInPrimary': {
      '0%': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.transparent,
        ),
      },
      '100%': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.primaryLightSmFaded,
        ),
      },
    },
    '@keyframes filterPulsePrimary': {
      '0%,100%': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.primaryLightSmFaded,
        ),
      },
      '50%': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.primaryLightLgFaded,
        ),
      },
    },
    wrapper: {
      position: 'relative',
      width: '100%',
      flexGrow: 1,
      maxWidth: 'var(--linkTileMaxWidth)',
      paddingBottom: 10,
      zIndex: 5,
      '&:hover': {
        zIndex: 4,
      },
      '&:hover $imageWrapper': {
        animationName: '$filterFadeInPrimary, $filterPulsePrimary',
        animationIterationCount: '1, infinite',
        animationDelay: [0, styling.speeds.jog],
        animationDuration: [styling.speeds.jog, styling.speeds.crawl],
        animationFillMode: 'forwards',
        animationTimingFunction: ['ease-in', 'ease-out'],
      },
      '&:hover $caret': {
        transform: 'translateX(-10px)',
      },
    },
    imageWrapper: {
      filter: styling.shadows.utils.svgFilter(
        styling.shadows.theme.transparent,
      ),
    },
    'size-md': {
      '--linkTileMaxWidth': '356px',
      '--linkTileCaretMarginLeft': 'auto',
      '--linkTileImageHeight': '224px',
      '& $shortImage': {
        '--linkTileImageHeight': '156px',
      },
    },
    'size-lg': {
      '--linkTileMaxWidth': '552px',
      '--linkTileCaretMarginLeft': '17px',
      '--linkTileImageHeight': '295px',
      '& $shortImage': {
        '--linkTileImageHeight': '205px',
      },
    },
    image: {
      height: 'var(--linkTileImageHeight)',
      width: '100%',
      marginBottom: 18,
    },
    shortImage: {},
    title: {
      display: 'flex',
      alignItems: 'center',
      '& + $body': {
        marginTop: 8,
      },
    },
    body: {
      '& + $download': {
        marginTop: 23,
      },
    },
    download: {},
    caret: {
      marginLeft: 'var(--linkTileCaretMarginLeft)',
      filter: styling.shadows.utils.svgFilter(styling.shadows.theme.primarySm),
      transform: 'translateX(0)',
      transition: styling.speeds.jog,
    },
  },
  { name: 'linkTile' },
);
