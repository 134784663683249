import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    wrapper: {},
  },
  { name: 'input' },
);
