import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Normalize } from 'styled-normalize';

import AlertRoot from './components/AlertRoot';
import GlobalFetcher from './components/GlobalFetcher';
import GlobalStyles from './components/GlobalStyles';
import ToastRoot from './components/ToastRoot';
import { AlertProvider } from './contexts/alertContext';
import { AnalyticsProvider } from './contexts/analyticsContext';
import { AttemptProvider } from './contexts/attemptContext';
import { ErrorCaptureProvider } from './contexts/errorCaptureContext';
import { InviteProvider } from './contexts/inviteContext';
import { MediaQueryProvider } from './contexts/mediaQueryContext';
import { PanelProvider } from './contexts/panelContext';
import { PersistentStorageProvider } from './contexts/persistentStorageContext';
import { RequestsProvider } from './contexts/requestsContext';
import { ToastProvider } from './contexts/toastContext';
import Landing from './scenes/landing';
import metaRouter from './scenes/meta/constants/metaRouter';
import MetaLanding from './scenes/meta-landing';
import router from './services/constants/router';
import storageFactory from './services/storage';

const App = () => (
  <>
    <Normalize />
    <GlobalStyles />
    <ErrorCaptureProvider>
      <AnalyticsProvider>
        <MediaQueryProvider>
          <ToastProvider>
            <AlertProvider>
              <RequestsProvider>
                <PersistentStorageProvider
                  namespace="conspiracy"
                  storageClass={storageFactory()}
                >
                  <InviteProvider>
                    <GlobalFetcher>
                      <AttemptProvider>
                        <PanelProvider>
                          {process.env.REACT_APP_RELEASE_SPACE ===
                            'landing' && <Landing />}
                          {process.env.REACT_APP_RELEASE_SPACE === 'meta' && (
                            <RouterProvider router={metaRouter} />
                          )}
                          {process.env.REACT_APP_RELEASE_SPACE ===
                            'meta-landing' && <MetaLanding />}
                          {!process.env.REACT_APP_RELEASE_SPACE && (
                            <RouterProvider router={router} />
                          )}
                          <AlertRoot />
                          <ToastRoot />
                        </PanelProvider>
                      </AttemptProvider>
                    </GlobalFetcher>
                  </InviteProvider>
                </PersistentStorageProvider>
              </RequestsProvider>
            </AlertProvider>
          </ToastProvider>
        </MediaQueryProvider>
      </AnalyticsProvider>
    </ErrorCaptureProvider>
  </>
);

export default App;
