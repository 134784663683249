import PropTypes from 'prop-types';
import clsx from 'clsx';

import Image from '../Image';
import useStyles from './clippedImage.styles';

const ClippedImage = ({ className, src, withRadialOverlay }) => {
  const styles = useStyles();

  return (
    <Image
      className={clsx(
        className,
        styles.clipped,
        withRadialOverlay && styles.radialOverlay,
      )}
      src={src}
    />
  );
};

ClippedImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  withRadialOverlay: PropTypes.bool.isRequired,
};

ClippedImage.defaultProps = {
  className: undefined,
};

export default ClippedImage;
