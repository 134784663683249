import PropTypes from 'prop-types';

import SlantedButton from '../../SlantedButton';
import useFormSubmitButton from './hooks/useFormSubmitButton';
import useStyles from './formSubmitButton.styles';

const FormSubmitButton = ({
  clearFormData,
  formState,
  getFormData,
  setFormState,
  onSubmit,
}) => {
  const styles = useStyles();
  const { isDisabled, onClick } = useFormSubmitButton({
    formState,
    setFormState,
    onSubmit,
    getFormData,
    clearFormData,
  });

  return (
    <div className={styles.wrapper}>
      <SlantedButton isDisabled={isDisabled} onClick={onClick} type="button">
        Submit
      </SlantedButton>
    </div>
  );
};

FormSubmitButton.propTypes = {
  clearFormData: PropTypes.func.isRequired,
  formState: PropTypes.oneOf(['CLEAN', 'SUBMITTING', 'ERROR']).isRequired,
  getFormData: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormSubmitButton;
