import { emptyImageSrc, minWaitTime } from './constants';

export const getFallbackImageSrc = () => emptyImageSrc;

const onError = (setIsLoaded, setIsErrored, setRenderableSrc) => () => {
  setIsLoaded(false);
  setIsErrored(true);
  setRenderableSrc(getFallbackImageSrc());
};

const onLoaded =
  (startTime, src, setIsLoaded, setIsErrored, setRenderableSrc) => () => {
    const waitTime = minWaitTime - (Date.now() - startTime);
    window.setTimeout(() => {
      setIsLoaded(true);
      setIsErrored(false);
      setRenderableSrc(src);
    }, waitTime); // negative numbers fire immediately
  };

export const loadImage = ({
  imageNodeRef,
  setImageNodeReference,
  src,
  setIsLoaded,
  setIsErrored,
  setRenderableSrc,
}) => {
  const handleUnmount = () => {
    if (!imageNodeRef) return;

    // eslint-disable-next-line no-param-reassign
    imageNodeRef.onload = () => {};
    setImageNodeReference();
    // cannot delete local variable in strict mode, may need to garbage collect
    // some other way
    // delete imageNodeRef;
  };

  const startTime = Date.now();

  const image = new window.Image();
  image.src = src;
  image.onload = onLoaded(
    startTime,
    src,
    setIsLoaded,
    setIsErrored,
    setRenderableSrc,
  );
  image.onerror = onError(setIsLoaded, setIsErrored, setRenderableSrc);
  setImageNodeReference(image);

  return handleUnmount;
};
