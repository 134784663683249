import PropTypes from 'prop-types';

import SlantedButton from '../../SlantedButton';
import useAlertButtons from './hooks/useAlertButtons';
import useStyles from './alertButtons.styles';

const AlertButtons = ({ buttons, onClose }) => {
  const styles = useStyles();
  const { generateOnClick } = useAlertButtons(onClose);

  if (buttons.length === 0) return null;

  return (
    <div className={styles.wrapper}>
      {buttons.map(({ title, onClick }, i) => (
        <SlantedButton
          className={styles.button}
          key={title}
          onClick={generateOnClick(onClick)}
          size="mdFixed"
          variant={i === buttons.length - 1 ? 'primary' : 'secondary'}
        >
          {title}
        </SlantedButton>
      ))}
    </div>
  );
};

AlertButtons.defaultProps = {
  buttons: [],
};

AlertButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ),
  onClose: PropTypes.func.isRequired,
};

export default AlertButtons;
