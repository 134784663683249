import PropTypes from 'prop-types';
import { Animate } from 'react-move';

import styling from '../../../../services/styling';
import useStatBar from './hooks/useStatBar';
import { BAR_THICKNESS } from './constants';
import useStyles from './statBar.styles';

const StatBar = ({ value, secondaryValue }) => {
  const styles = useStyles();
  const { ref, inView, secondaryBarWidth } = useStatBar(value, secondaryValue);
  return (
    <svg
      ref={ref}
      className={styles.wrapper}
      height={BAR_THICKNESS}
      width="100%"
    >
      <g transform={`translate(0,${BAR_THICKNESS * -0.5})`}>
        <line
          className={styles.backgroundBar}
          strokeWidth={BAR_THICKNESS}
          x1="0"
          x2="100%"
          y1={`${BAR_THICKNESS}`}
          y2={`${BAR_THICKNESS}`}
        />
        <Animate
          start={{
            primaryWidth: 0,
            secondaryWidth: 0,
          }}
          update={{
            primaryWidth: [inView ? value : 0],
            secondaryWidth: [inView ? secondaryBarWidth : 0],
            timing: { duration: parseInt(styling.speeds.walk, 10) },
          }}
        >
          {({ primaryWidth, secondaryWidth }) => (
            <>
              {/* secondary line so that primary overlaps */}
              <line
                className={styles.secondaryBar}
                strokeLinecap={secondaryWidth ? 'round' : undefined}
                strokeWidth={BAR_THICKNESS}
                x1="0"
                x2={`${secondaryWidth}%`}
                y1={`${BAR_THICKNESS}`}
                y2={`${BAR_THICKNESS}`}
              />
              <line
                className={styles.primaryBar}
                strokeLinecap={primaryWidth ? 'round' : undefined}
                strokeWidth={BAR_THICKNESS}
                x1="0"
                x2={`${primaryWidth}%`}
                y1={`${BAR_THICKNESS}`}
                y2={`${BAR_THICKNESS}`}
              />
            </>
          )}
        </Animate>
      </g>
    </svg>
  );
};

StatBar.propTypes = {
  secondaryValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default StatBar;
