import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

const CLIP_PATH_SIZE = 40;

export default createUseStyles(
  {
    clipped: {
      clipPath: `polygon(${CLIP_PATH_SIZE}px 0, 100% 0, 100% calc(100% - ${CLIP_PATH_SIZE}px), calc(100% - ${CLIP_PATH_SIZE}px) 100%, 0 100%, 0 ${CLIP_PATH_SIZE}px)`,
    },
    radialOverlay: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        opacity: 0.5,
        background: `radial-gradient(circle, ${styling.colors.theme.transparent} 0%, ${styling.colors.theme.pureBlack} 100%)`,
      },
    },
  },
  { name: 'clippedImage' },
);
