import PropTypes from 'prop-types';

import InputWrapper from '../InputWrapper';
import useInputText from './hooks/useInputText';
import useStyles from './inputText.styles';

const InputText = ({
  className,
  required,
  disabled,
  errors,
  isFocused,
  name,
  onBlur,
  onChange,
  onFocus,
  value,
  type,
  meta,
}) => {
  const styles = useStyles();
  const { handleOnChange } = useInputText(type, onChange);

  return (
    <InputWrapper
      className={className}
      errors={errors}
      isDisabled={disabled}
      isFocused={isFocused}
    >
      <input
        autoFocus={meta?.autoFocus}
        className={styles.input}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        name={name}
        required={required}
        type={type}
        value={value || ''}
      />
    </InputWrapper>
  );
};

InputText.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['email', 'number', 'text']).isRequired,
  name: PropTypes.string,
  meta: PropTypes.shape({
    autoFocus: PropTypes.bool,
  }),
};

InputText.defaultProps = {
  className: undefined,
  disabled: false,
  required: false,
  errors: undefined,
  isFocused: false,
  name: undefined,
  value: undefined,
  meta: undefined,
};

export default InputText;
