import { useCallback, useEffect, useState } from 'react';

import { handleOnCueChange, handleOnPlayerMount } from '../logic';

const usePlayerCaptions = (playerRef) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [caption, setCaption] = useState('');

  const onCueChange = useCallback(
    (e) => handleOnCueChange(e, setCaption),
    [setCaption],
  );

  useEffect(
    () => handleOnPlayerMount(playerRef, onCueChange, trackIndex),
    [playerRef, onCueChange, trackIndex],
  );

  return {
    caption,
  };
};

export default usePlayerCaptions;
