import { useCallback } from 'react';

import { generateHandleOnChange } from '../logic';

const useInputText = (type, onChange) => {
  const handleOnChange = useCallback(
    (e) => generateHandleOnChange(type, onChange, e?.target?.value),
    [type, onChange],
  );

  return {
    handleOnChange,
  };
};

export default useInputText;
