import PropTypes from 'prop-types';
import clsx from 'clsx';

import Lottie from '../Lottie';
import animationData from './animationData.json';
import useStyles from './loadingSpinner.styles';

const LoadingSpinner = ({ className, size }) => {
  const styles = useStyles();

  return (
    <Lottie
      className={clsx(className, styles.wrapper)}
      animationData={animationData}
      height={size}
      width={size}
    />
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LoadingSpinner.defaultProps = {
  className: undefined,
  size: 200,
};

export default LoadingSpinner;
