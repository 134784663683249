import { useContext } from 'react';

import { MediaQueryContext } from '../../../../../../contexts/mediaQueryContext';

const useNavigationText = (isNext) => {
  const { isDesktop } = useContext(MediaQueryContext);

  const buttonText = isNext ? 'NEXT' : 'PREV';
  const transparentButtonText = isDesktop ? buttonText : '';

  return transparentButtonText;
};

export default useNavigationText;
