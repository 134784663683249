import { useMemo } from 'react';

import { calculateStyle } from '../logic';

const useTileBackground = (corner) => {
  const style = useMemo(() => calculateStyle(corner), [corner]);

  return {
    style,
  };
};

export default useTileBackground;
