import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import landingHow from '../../../static/images/landing-how.webp';

export default createUseStyles(
  {
    what: {
      textAlign: 'center',
      margin: [0, 'auto', 100],
      maxWidth: styling.sizes.landingSectionMaxWidth,
      '& > h2': {
        marginBottom: 57,
      },
      '& p + p': {
        marginTop: 10,
      },
    },
    how: {
      textAlign: 'center',
      maxWidth: styling.sizes.landingSectionMaxWidth,
      margin: [9, 'auto', 114],
      [styling.breakpoints.up('md')]: {
        marginTop: -67,
      },
    },
    landingHowWrapper: {
      overflow: 'hidden',
      margin: [43, 'auto', 78],
      [styling.breakpoints.up('md')]: {
        margin: [0, 'auto', 100],
      },
    },
    landingHowInner: {
      transform: 'scale(1.17)',
      transformOrigin: 'center top',
      [styling.breakpoints.up('md')]: {
        transform: 'none',
        maxHeight: 608,
      },
    },
    landingHow: {
      margin: [0, 'auto'],
      width: '100%',
      maxWidth: 1013,
      paddingBottom: '70%',
      background: `url(${landingHow})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    features: {
      maxWidth: styling.sizes.landingSectionMaxWidth,
      margin: [0, 'auto', 76],
      '& ul': {
        margin: [10, 0, 0],
        padding: [0, 0, 0, 20],
      },
      '& li': {
        marginBottom: 10,
      },
    },
    results: {
      textAlign: 'center',
      maxWidth: styling.sizes.landingSectionMaxWidth,
      margin: [0, 'auto', 60],
      '& > span': {
        color: styling.colors.theme.primaryLight,
      },
    },
  },
  { name: 'landingWhatHow' },
);
