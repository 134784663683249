import styling from '../../services/styling';

const body = {
  defaultComponent: 'p',
  styles: {
    fontSize: '1.6rem',
    lineHeight: '2.6rem',
    fontWeight: styling.fonts.barlow.weights.regular,
    color: styling.colors.context.text,
  },
  shadow: {},
  hoverShadow: {},
};

const bodySmall = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
  },
};

const caption = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '1rem',
  },
};

const link = {
  ...body,
  defaultComponent: 'span',
  styles: {
    ...body.styles,
    fontWeight: styling.fonts.barlow.weights.semiBold,
    color: styling.colors.context.link,
  },
};

const title = {
  defaultComponent: 'h2',
  styles: {
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.light,
    fontSize: '7rem',
    lineHeight: '7rem',
    letterSpacing: '0.175rem',
    color: styling.colors.context.title,
    textTransform: 'uppercase',
  },
  shadow: {
    textShadow: styling.shadows.context.title,
  },
  hoverShadow: {
    textShadow: styling.shadows.context.titleHover,
  },
};

const subtitle = {
  ...body,
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.regular,
    fontSize: '2.5rem',
    lineHeight: '3.5rem',
    letterSpacing: '0.063rem',
  },
  shadow: {
    textShadow: styling.shadows.context.subtitle,
  },
  hoverShadow: {
    textShadow: styling.shadows.context.subtitleHover,
  },
};

const videoCaption = {
  ...subtitle,
  styles: {
    ...subtitle.styles,
    fontWeight: styling.fonts.barlowCondensed.weights.medium,
    lineHeight: '3rem',
    whiteSpace: 'pre',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryLightSmFaded,
  },
};

const videoCaptionMobile = {
  ...videoCaption,
  styles: {
    ...videoCaption.styles,
    fontSize: '2rem',
    lineHeight: '2.4rem',
  },
};

const inputLabel = {
  defaultComponent: 'label',
  styles: {
    color: styling.colors.theme.primaryLight,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    textTransform: 'uppercase',
    fontSize: '2.5rem',
    lineHeight: '5.6rem',
    letterSpacing: '0.063rem',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryMd,
  },
};

const inputValue = {
  defaultComponent: 'p',
  styles: {
    color: styling.colors.context.text,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.regular,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    letterSpacing: '0.05rem',
  },
  shadow: {},
};

const inputValueSm = {
  ...inputValue,
  styles: {
    ...inputValue.styles,
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    letterSpacing: '0.05rem',
  },
};

const inputValueLg = {
  ...inputValue,
  styles: {
    ...inputValue.styles,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    textTransform: 'uppercase',
    fontSize: '2.5rem',
    lineHeight: '5.6rem',
    letterSpacing: '0.063rem',
  },
};

const inputErrors = {
  ...body,
  styles: {
    ...body.styles,
    lineHeight: '1.6rem',
    color: styling.colors.theme.error,
    textShadow: 'none',
  },
};

const primaryNav = {
  ...title,
  styles: {
    ...title.styles,
    fontSize: '6rem',
    lineHeight: '6rem',
    letterSpacing: '0.15rem',
  },
};

const secondaryNav = {
  defaultComponent: 'h4',
  styles: {
    ...title.styles,
    fontSize: '3rem',
    lineHeight: '4rem',
    letterSpacing: '0.075rem',
    color: styling.colors.context.secondaryNav,
  },
  shadow: {
    textShadow: styling.shadows.context.secondaryNav,
  },
  hoverShadow: {
    textShadow: styling.shadows.context.secondaryNavHover,
  },
};

const videoCta = {
  ...subtitle,
  styles: {
    ...subtitle.styles,
    fontSize: '2rem',
    lineHeight: '2rem',
    letterSpacing: '-0.013rem',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryXs,
  },
};

const tileTitle = {
  ...body,
  defaultComponent: 'h5',
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.medium,
    fontSize: '2rem',
    lineHeight: '2rem',
    letterSpacing: '0.05rem',
  },
};

const tileDownloadTitle = {
  ...body,
  defaultComponent: 'h5',
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2.4rem',
    lineHeight: '2.4rem',
    letterSpacing: '-0.015rem',
    textTransform: 'uppercase',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryXs,
  },
};

const carouselTileTitle = {
  ...title,
  styles: {
    ...title.styles,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '6rem',
    lineHeight: '10.5rem',
    letterSpacing: '0.15rem',
    textAlign: 'center',
  },
  hoverShadow: {},
};

const carouselTileTitleMobile = {
  ...carouselTileTitle,
  styles: {
    ...carouselTileTitle.styles,
    fontSize: '3rem',
    lineHeight: '5.5rem',
    letterSpacing: '0.075rem',
  },
};

const carouselTileBody = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '1.8rem',
    lineHeight: '2.8rem',
    textAlign: 'center',
  },
  shadow: {
    textShadow: styling.shadows.context.title,
  },
};

const carouselTileBodyMobile = {
  ...carouselTileBody,
  styles: {
    ...carouselTileBody.styles,
    fontSize: '1.65rem',
    lineHeight: '2.6rem',
  },
};

const carouselPrimaryNav = {
  ...body,
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2.3rem',
    lineHeight: '3.9rem',
    textAlign: 'center',
  },
  shadow: {
    textShadow: styling.shadows.theme.primarySm,
  },
};

const carouselSecondaryNav = {
  ...body,
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2.3rem',
    lineHeight: '3.9rem',
    textAlign: 'center',
  },
  shadow: {
    textShadow: styling.shadows.theme.secondarySm,
  },
};

const statWheelLg = {
  defaultComponent: 'span',
  styles: {
    color: styling.colors.theme.primaryLight,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '11rem',
    lineHeight: '14.2rem',
    textAlign: 'center',
    letterSpacing: '0.175rem',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryLgFaded,
  },
};

const statWheelPrimary = {
  defaultComponent: 'span',
  styles: {
    color: styling.colors.theme.primaryLight,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '3rem',
    lineHeight: '3.5rem',
    textAlign: 'center',
    letterSpacing: '0.075rem',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryLgFaded,
  },
};

const statWheelSecondary = {
  ...statWheelPrimary,
  styles: {
    ...statWheelPrimary.styles,
    color: styling.colors.theme.secondary,
  },
  shadow: {
    textShadow: styling.shadows.theme.secondaryLgFaded,
  },
};

const statDisplayTitle = {
  defaultComponent: 'h5',
  styles: {
    color: styling.colors.theme.pureWhite,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2.3rem',
    lineHeight: '2.7rem',
    textAlign: 'center',
    letterSpacing: '0.05rem',
    textTransform: 'uppercase',
  },
};

const statBarTitle = {
  defaultComponent: 'span',
  styles: {
    color: styling.colors.theme.pureWhite,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    letterSpacing: '0.05rem',
    textTransform: 'uppercase',
  },
};

const statBarValue = {
  ...statBarTitle,
  styles: {
    ...statBarTitle.styles,
    fontWeight: styling.fonts.barlowCondensed.weights.regular,
    fontSize: '4rem',
    lineHeight: '4rem',
    letterSpacing: '0.1rem',
    textAlign: 'right',
  },
};

const statsNoDataAvailableTitle = {
  ...title,
  styles: {
    ...title.styles,
    fontWeight: styling.fonts.barlowCondensed.weights.medium,
    fontSize: '3.6rem',
    lineHeight: '5.6rem',
    letterSpacing: '0.063rem',
  },
};

const questionTitle = {
  ...title,
  styles: {
    ...title.styles,
    fontSize: '6rem',
    lineHeight: '7.6rem',
    letterSpacing: '0.24rem',
  },
};

const questionTitleMobile = {
  ...questionTitle,
  styles: {
    ...questionTitle.styles,
    fontSize: '4rem',
    lineHeight: '4.5rem',
    letterSpacing: '0.1rem',
  },
  shadow: {
    textShadow: styling.shadows.theme.primaryMd,
  },
};

const questionSubtitle = {
  ...subtitle,
};

const questionSubtitleMobile = {
  ...questionSubtitle,
  styles: {
    ...questionSubtitle.styles,
    fontSize: '2rem',
    lineHeight: '3rem',
    letterSpacing: '0.05rem',
  },
};

const carouselQuestionBody = {
  ...body,
  styles: {
    ...body.styles,
    lineHeight: '2.7rem',
  },
};

const carouselQuestionTitle = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    color: styling.colors.context.title,
    fontSize: '2.4rem',
    lineHeight: '2.88rem',
    letterSpacing: '0.06rem',
    textTransform: 'uppercase',
  },
  shadow: {
    textShadow: styling.shadows.context.title,
  },
  hoverShadow: {},
};

const questionRatingValue = {
  ...body,
  styles: {
    ...body.styles,
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontSize: '6rem',
    lineHeight: '10.5rem',
    textAlign: 'center',
  },
};

const questionMissionName = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '1.6rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    letterSpacing: '0.063rem',
  },
};

const questionScoreHeading = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '2rem',
    lineHeight: '2rem',
    textTransform: 'uppercase',
    letterSpacing: '0.3rem',
  },
};

const questionScoreValue = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.medium,
    color: styling.colors.context.title,
    fontSize: '6rem',
    lineHeight: '8rem',
    letterSpacing: '0.24rem',
    textAlign: 'center',
  },
  shadow: {},
  hoverShadow: {},
};

const toastTitle = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.semiBold,
    fontSize: '2.4rem',
    lineHeight: '2.88rem',
    letterSpacing: '0.113rem',
    color: styling.colors.theme.softBlack,
    textTransform: 'uppercase',
  },
  shadow: {},
  hoverShadow: {},
};

const toastBody = {
  ...body,
  styles: {
    ...body.styles,
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: styling.colors.theme.softBlack,
  },
};

const alertTitle = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.barlowCondensed.fontFamily,
    fontWeight: styling.fonts.barlowCondensed.weights.light,
    fontSize: '4.5rem',
    lineHeight: '5rem',
    letterSpacing: '0.113rem',
    textTransform: 'uppercase',
    color: styling.colors.context.title,
  },
  shadow: {
    textShadow: styling.shadows.context.title,
  },
  hoverShadow: {},
};

const alertBody = {
  ...subtitle,
  styles: {
    ...subtitle.styles,
    '& a': {
      fontWeight: styling.fonts.barlow.weights.semiBold,
      color: styling.colors.context.link,
    },
  },
};

const metaBody = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.ibmPlexMono.fontFamily,
    fontWeight: styling.fonts.ibmPlexMono.weights.semiBold,
    fontSize: '1.8rem',
    lineHeight: '2.25rem',
  },
  shadow: {},
  hoverShadow: {},
};

const metaBodySmall = {
  ...metaBody,
  styles: {
    ...metaBody.styles,
    fontSize: '1.6rem',
    lineHeight: '1.92rem',
  },
};

const metaTitle = {
  defaultComponent: 'h2',
  styles: {
    fontFamily: styling.fonts.americanCaptain.fontFamily,
    fontWeight: styling.fonts.americanCaptain.weights.regular,
    fontSize: '6.4rem',
    lineHeight: '5.4rem',
    textTransform: 'uppercase',
    [styling.breakpoints.up('sm')]: {
      fontSize: '9.6rem',
      lineHeight: '7.6rem',
    },
    [styling.breakpoints.up('md')]: {
      fontSize: '12.8rem',
      lineHeight: '10.8rem',
    },
  },
  shadow: {},
  hoverShadow: {},
};

const metaButton = {
  defaultComponent: 'p',
  styles: {
    fontFamily: styling.fonts.americanCaptain.fontFamily,
    fontWeight: styling.fonts.americanCaptain.weights.regular,
    fontSize: '3rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    [styling.breakpoints.up('md')]: {
      fontSize: '4rem',
      lineHeight: '3.2rem',
    },
  },
  shadow: {},
  hoverShadow: {},
};

export const variantMapping = {
  caption,
  body,
  bodySmall,
  link,
  title,
  subtitle,
  videoCaption,
  videoCaptionMobile,
  inputLabel,
  inputValue,
  inputValueSm,
  inputValueLg,
  inputErrors,
  primaryNav,
  secondaryNav,
  videoCta,
  tileTitle,
  tileDownloadTitle,
  carouselTileTitle,
  carouselTileTitleMobile,
  carouselTileBody,
  carouselTileBodyMobile,
  carouselPrimaryNav,
  carouselSecondaryNav,
  statWheelLg,
  statWheelPrimary,
  statWheelSecondary,
  statDisplayTitle,
  statBarTitle,
  statBarValue,
  statsNoDataAvailableTitle,
  questionTitle,
  questionTitleMobile,
  questionSubtitle,
  questionSubtitleMobile,
  carouselQuestionBody,
  carouselQuestionTitle,
  questionRatingValue,
  questionMissionName,
  questionScoreHeading,
  questionScoreValue,
  toastTitle,
  toastBody,
  alertTitle,
  alertBody,
  metaBody,
  metaBodySmall,
  metaTitle,
  metaButton,
};

export const variantMappingKeys = Object.keys(variantMapping);
export const variantMappingDefaultKey = 'body';
