/* eslint-disable react/no-unknown-property */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useFrame, useLoader } from '@react-three/fiber';

const useLoadModel = (model) => {
  const mixer = useRef();
  const gltf = useLoader(GLTFLoader, model);

  // Here's the animation part
  // *************************
  useEffect(() => {
    if (gltf.animations.length) {
      mixer.current = new THREE.AnimationMixer(gltf.scene);
      gltf.animations.forEach((clip) => {
        const action = mixer.current.clipAction(clip);
        action.play();
      });
    }
  }, [gltf]);

  useFrame((state, delta) => {
    mixer.current?.update(delta);
  });
  // *************************

  return gltf.scene;
};

function CustomModel({ model }) {
  const scene = useLoadModel(model);

  return <primitive object={scene} />;
}

CustomModel.propTypes = {
  model: PropTypes.string.isRequired,
};

export default CustomModel;
