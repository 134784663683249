import PropTypes from 'prop-types';
import clsx from 'clsx';

import LoadingSpinner from '../LoadingSpinner';
import useStyles from './loadingOverlay.styles';

const LoadingOverlay = ({ className, hasBackground, spinnerSize }) => {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        className,
        styles.wrapper,
        hasBackground && styles.hasBackground,
      )}
    >
      <LoadingSpinner size={spinnerSize} />
    </div>
  );
};

LoadingOverlay.propTypes = {
  className: PropTypes.string,
  spinnerSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasBackground: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  className: undefined,
  spinnerSize: undefined,
  hasBackground: false,
};

export default LoadingOverlay;
