import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useToastManagement from '../hooks/useToastManagement';

export const ToastContext = createContext({
  closeToast: () => {},
  topToast: undefined,
  toasts: [],
  registerToast: () => {},
  deregisterToast: () => {},
});

export const ToastProvider = ({ children }) => {
  const value = useToastManagement();

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
