export const calculateStyle = (width, height) => ({
  width,
  height,
});

export const handleLoadAnimation = ({
  elementRef,
  lottieInstance,
  setLottieInstance,
  animationData,
  loadAnimation,
}) => {
  if (elementRef) {
    setLottieInstance(
      loadAnimation({
        animationData,
        container: elementRef,
      }),
    );
  }
  return () => {
    lottieInstance?.destroy();
  };
};
