export const createYoutubeEmbedUrl = (embedId) =>
  `https://www.youtube-nocookie.com/watch?v=${embedId}`;

export const createVimeoEmbedUrl = (embedId) => `https://vimeo.com/${embedId}`;

export const getPlayerSource = (mission, question, step) => {
  const missionSlug = mission?.slug;
  const questionStep = question?.questionSteps?.[step];
  const youtubeEmbedId = questionStep?.meta?.youtubeEmbedIds?.[missionSlug];
  const vimeoEmbedId = questionStep?.meta?.vimeoEmbedIds?.[missionSlug];

  if (youtubeEmbedId) {
    return createYoutubeEmbedUrl(youtubeEmbedId);
  }
  if (vimeoEmbedId) {
    return createVimeoEmbedUrl(vimeoEmbedId);
  }
  return null;
};
