import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';
import { BAR_THICKNESS } from './constants';

export default createUseStyles(
  {
    wrapper: {
      marginTop: 20,
      borderRadius: BAR_THICKNESS,
    },
    backgroundBar: {
      stroke: styling.colors.theme.pureWhiteFaded,
    },
    primaryBar: {
      stroke: styling.colors.theme.secondary,
    },
    secondaryBar: {
      stroke: styling.colors.theme.primaryLight,
    },
  },
  { name: 'statBar' },
);
