import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './iconButton.styles';

const IconButton = ({ className, icon, onClick }) => {
  const styles = useStyles();
  const Icon = icon;

  return (
    <button
      type="button"
      className={clsx(className, styles.button)}
      onClick={onClick}
    >
      <Icon />
    </button>
  );
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  className: undefined,
};

export default IconButton;
