import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

export default createUseStyles(
  {
    container: {
      position: 'absolute',
      left: '50%',
      top: 0,
      width: '62%',
      aspectRatio: '976 / 1464',
      boxShadow: styling.shadows.utils.boxShadowToString({
        x: 0,
        y: 4,
        blur: 24,
        spread: 24,
        inset: null,
        color: styling.colors.theme.metaSecondaryDeepDarkFaded,
      }),
      opacity: 'var(--posterImageInView)',
      rotate: 'calc(10deg * var(--posterImageOffset))',
      scale: 'calc(1 - (var(--posterImageAbsOffset) * 0.2))',
      translate:
        'calc(-50% + var(--posterImageOffset) * 110%) calc(var(--posterImageAbsOffset) * 17% + ((var(--posterImageAbsOffset) - 1) * 10%))',
      transition: `opacity 2000ms ease-in-out, rotate 2000ms ease-in-out, scale 2000ms ease-in-out, translate 2000ms ease-in-out`,
      [styling.breakpoints.up('sm')]: {
        width: '52%',
      },
      [styling.breakpoints.up('md')]: {
        width: '32%',
      },
    },
    containerSelected: {
      translate: '-50% 0',
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
  { name: 'posterImage' },
);
