import { useContext, useEffect, useRef } from 'react';

import { RequestsContext } from '../../../contexts/requestsContext';
import useAsyncRequest from '../../../hooks/useAsyncRequest';

const use404PageContent = () => {
  const pageContent = useRef({});
  const [performRequest] = useContext(RequestsContext);
  const [fetchPage, fetchState] = useAsyncRequest(
    () =>
      performRequest({
        method: 'GET',
        url: '/data/pages/404.json',
      }),
    (newPageContent) => {
      pageContent.current = newPageContent;
    },
  );

  useEffect(() => {
    fetchPage();
  }, []);

  return {
    pageContent: pageContent.current,
    pageContentState: fetchState,
  };
};

export default use404PageContent;
