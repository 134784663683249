import InputCheckbox from './InputCheckbox';
import InputSelect from './InputSelect';
import InputText from './InputText';
import InputTextarea from './InputTextarea';
import InputTypeahead from './InputTypeahead';

export const getComponentForType = (type) => {
  switch (type) {
    case 'number':
    case 'email':
    case 'text':
      return {
        component: InputText,
        props: {
          type,
        },
      };
    case 'select':
      return {
        component: InputSelect,
        props: {},
      };
    case 'typeahead':
      return {
        component: InputTypeahead,
        props: {},
      };
    case 'checkbox':
      return {
        component: InputCheckbox,
        props: {},
      };
    case 'textarea':
      return {
        component: InputTextarea,
        props: {},
      };
    default:
      return {
        component: 'div',
        props: {},
      };
  }
};

export const handleOnBlurFocus = (setIsFocused, value) => setIsFocused(value);

export const handleOnTouched = (setIsTouched) => setIsTouched(true);

export const getTouchedErrors = (errors, isTouched) => {
  if (!isTouched) return undefined;
  return errors;
};
