import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

export default createUseStyles(
  {
    moveable: {
      display: 'inline-block',
      cursor: 'pointer',
      userSelect: 'none',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      '&:hover $caretLeft': {
        transform: 'translateX(-10px)',
      },
      '&:hover $caretRight': {
        transform: 'translateX(10px)',
      },
    },
    caretLeft: {
      filter: styling.shadows.utils.svgFilter(
        styling.shadows.theme.secondarySm,
      ),
      transform: 'translateX(0)',
      transition: styling.speeds.jog,
      [styling.breakpoints.up('md')]: {
        marginRight: 19,
      },
    },
    caretRight: {
      filter: styling.shadows.utils.svgFilter(styling.shadows.theme.primarySm),
      transform: 'translateX(0)',
      transition: styling.speeds.jog,
      [styling.breakpoints.up('md')]: {
        marginLeft: 19,
      },
    },
  },
  { name: 'carousel-navigation' },
);
