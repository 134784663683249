import PropTypes from 'prop-types';
import clsx from 'clsx';

import useIconSvg from './hooks/useIconSvg';
import useStyles from './iconSvg.styles';

const IconSvg = ({ className, color, children, ...rest }) => {
  const { style } = useIconSvg(color);
  const styles = useStyles();

  return (
    <svg {...rest} className={clsx(className, styles.color)} style={style}>
      {children}
    </svg>
  );
};

IconSvg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
};

IconSvg.defaultProps = {
  className: undefined,
};

export default IconSvg;
