import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import { variantMapping } from '../../Typography/constants';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 25,
      position: 'relative',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      height: 0,
      width: 0,
    },
    disabledLabel: {
      opacity: 0.33,
    },
  },
  { name: 'inputCheckbox' },
);
