export const getOpenPanel = (setOpenedPanel) => (panelId) => {
  setOpenedPanel(panelId);
  window.document.scrollingElement.classList.add('lock-scroll-panel');
};

export const getClosePanel = (setOpenedPanel) => () => {
  setOpenedPanel();
  window.document.scrollingElement.classList.remove('lock-scroll-panel');
};

export const getRegisterPanel = (setPanels) => (panelId, renderMethod) => {
  setPanels((panels) => ({
    ...panels,
    [panelId]: renderMethod,
  }));
};

export const getDeregisterPanels = (setPanels) => (panelId) => {
  setPanels((panels) => {
    const { [panelId]: deleted, ...newPanels } = panels;
    return newPanels;
  });
};
