import { createUseStyles } from 'react-jss';

import styling from '../../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      height: 35,
      width: 35,
      border: [2, 'solid', styling.colors.theme.primaryLight],
      position: 'relative',
      cursor: 'pointer',
      boxShadow: styling.shadows.theme.transparent,
      transition: styling.speeds.jog,
    },
    check: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    focused: {
      boxShadow: styling.shadows.theme.primaryLg,
    },
    disabled: {
      opacity: 0.33,
      cursor: 'pointer',
    },
    errored: {
      boxShadow: styling.shadows.theme.errorLg,
      borderColor: styling.colors.theme.error,
    },
  },
  { name: 'checkbox' },
);
