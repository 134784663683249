const panel = 1500;
const alert = 2000;

export default {
  alert,
  panel,
  panelToggle: panel + 1,
  backdrops: {
    activeMission: panel + 10 - 1,
    alert: alert - 1,
    panel: panel - 1,
  },
  activeMission: panel + 10,
  toast: panel + 100,
};
