import PropTypes from 'prop-types';

import useGlobalContent from '../../../../hooks/useGlobalContent';
import IconButton from '../../../IconButton';
import LinkTile from '../../../LinkTile';
import Panel from '../../../Panel';
import Typography from '../../../Typography';
import useStyles from './sOTNavPanel.styles';

const SOTNavPanel = ({ isOpen }) => {
  const styles = useStyles();
  const {
    content: { sotLinks },
  } = useGlobalContent();

  return (
    <Panel docked="left" isOpen={isOpen}>
      <div className={styles.wrapper}>
        <nav className={styles.sotNav}>
          {sotLinks &&
            sotLinks.map(({ title, href, imgSrc }, index) => (
              <LinkTile
                key={index}
                href={href}
                imgSrc={imgSrc}
                shortImage
                title={title}
              />
            ))}
        </nav>
      </div>
    </Panel>
  );
};

SOTNavPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SOTNavPanel;
