import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';
import quasar from '../../static/images/quasar.webp';
import quasarSm from '../../static/images/quasar-sm.webp';

export default createUseStyles(
  {
    wrapper: {
      backgroundImage: `url(${quasarSm})`,
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      [styling.breakpoints.up('md')]: {
        backgroundImage: `url(${quasar})`,
      },
    },
    headerWrapper: {
      maxWidth: styling.breakpoints.values.xl,
      margin: [0, 'auto'],
      padding: [0, styling.sizes.gutteringSm],
      [styling.breakpoints.up('md')]: {
        padding: [0, styling.sizes.gutteringLg],
      },
    },
    absHeader: {
      width: `calc(100% - ${2 * styling.sizes.gutteringSm}px)`,
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 5,
      [styling.breakpoints.up('md')]: {
        width: `calc(100% - ${2 * styling.sizes.gutteringLg}px)`,
      },
    },
    body: {
      position: 'relative',
      zIndex: 3,
      display: 'grid',
      gridTemplateColumns: '1fr min(1140px, 100%) 1fr',
      padding: [0, styling.sizes.gutteringSm],
      minHeight: 1000,
      [styling.breakpoints.up('md')]: {
        padding: [0, styling.sizes.gutteringLg],
      },
      '& > *': {
        gridColumn: 2,
      },
      '& .full-bleed': {
        width: `calc(100% + ${styling.sizes.gutteringSm * 2}px)`,
        gridColumn: '1 / 4',
        marginLeft: -styling.sizes.gutteringSm,
        marginRight: -styling.sizes.gutteringSm,
        [styling.breakpoints.up('md')]: {
          width: `calc(100% + ${styling.sizes.gutteringLg * 2}px)`,
          marginLeft: -styling.sizes.gutteringLg,
          marginRight: -styling.sizes.gutteringLg,
        },
      },
    },
  },
  { name: 'layoutBrochure' },
);
