import { getRandomIntBetween } from '../../services/helpers/number';

export const getInitialIndex = (missions, missionSlug) => {
  const foundMissionIndex = missions?.findIndex(
    (mission) => mission.slug === missionSlug,
  );

  if (foundMissionIndex >= 0) return foundMissionIndex;

  return getRandomIntBetween(0, missions.length);
};
