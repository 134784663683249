import { useCallback } from 'react';

const useAlertButtons = (onClose) => {
  const generateOnClick = useCallback(
    (onClick) => () => {
      onClick();
      onClose();
    },
    [onClose],
  );

  return {
    generateOnClick,
  };
};

export default useAlertButtons;
