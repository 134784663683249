import { useContext, useMemo } from 'react';

import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';
import styling from '../../../../services/styling';
import { calculateStyles } from '../logic';

const useFormGrid = (widths) => {
  const { isXs, isSm, isMd, isLg, isXl } = useContext(MediaQueryContext);
  const style = useMemo(
    () => calculateStyles(widths, isXs, isSm, isMd, isLg, isXl),
    [widths, isXs, isSm, isMd, isLg, isXl],
  );

  return {
    style,
  };
};

export default useFormGrid;
