import { appendParamsIfTheyExist } from '../../InternalLink/logic';

export const handleOnCloseAlert = (closeAlert) => {
  closeAlert('resumeMission');
};

export const handleOnNoResumeClick = (clearAttempt) => {
  clearAttempt();
};

export const handleOnResumeClick =
  (pathGenerator, path, missionSlug, navigate) => () => {
    navigate(appendParamsIfTheyExist(pathGenerator(path, { missionSlug })));
  };

export const handleOnMount = ({
  attemptData,
  registerAlert,
  onNoResumeClick,
  onResumeClick,
  missions,
  pathGenerator,
  missionPath,
  matches,
}) => {
  const foundMission = missions.find(
    (mission) => mission.slug === attemptData?.missionSlug,
  );
  if (!foundMission) return;

  const currentPath = matches?.slice(-1)?.[0];
  if (
    pathGenerator(missionPath, {
      missionSlug: currentPath?.params?.missionSlug || 'does-not-exist',
    }) === currentPath?.pathname
  ) {
    return;
  }

  registerAlert('resumeMission', {
    isRequired: true,
    title: 'Resume mission?',
    body: `It looks like you&rsquo;ve got an active ${foundMission.title} mission, want to resume?`,
    buttons: [
      {
        title: 'No thanks',
        onClick: onNoResumeClick,
      },
      {
        title: 'Yes resume',
        onClick: onResumeClick(foundMission.slug),
      },
    ],
  });
};
