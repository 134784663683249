import { useCallback, useRef, useState } from 'react';

import {
  getComponentForType,
  getTouchedErrors,
  handleOnBlurFocus,
  handleOnTouched,
} from '../logic';

const useInput = (type, errors) => {
  const component = useRef(getComponentForType(type));
  const [isFocused, setIsFocused] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const touchedErrors = getTouchedErrors(errors, isTouched);

  const handleOnFocus = useCallback(
    () => handleOnBlurFocus(setIsFocused, true),
    [setIsFocused],
  );
  const handleOnBlur = useCallback(
    (e) => {
      if (
        (type === 'typeahead' || type === 'select') &&
        e.currentTarget.parentElement.contains(e.relatedTarget)
      ) {
        return;
      }

      handleOnBlurFocus(setIsFocused, false);
      window.setTimeout(() => {
        handleOnTouched(setIsTouched);
      }, 0);
    },
    [setIsFocused],
  );

  return {
    InputComponent: component?.current.component,
    inputProps: component?.current.props,
    isFocused,
    handleOnFocus,
    handleOnBlur,
    touchedErrors,
  };
};

export default useInput;
