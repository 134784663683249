const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const getValueForKey = (key) => {
  if (breakpoints[key] !== undefined) {
    return breakpoints[key];
  }
  throw new Error(`[breakpoints] key not found: ${key}`);
};

const up = (key) => `@media (min-width: ${getValueForKey(key)}px)`;

const down = (key) => `@media (max-width: ${getValueForKey(key)}px)`;

export default {
  up,
  down,
  values: breakpoints,
};
