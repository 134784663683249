import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    wrapper: {
      position: 'absolute',
      bottom: 80,
      left: 0,
      right: 0,
      textAlign: 'center',
      zIndex: 5,
      padding: [0, styling.sizes.gutteringSm],
      pointerEvents: 'none',
      opacity: 0,
      transition: styling.speeds.jog,
      '& b': {
        color: styling.colors.theme.primaryLight,
      },
    },
    active: {
      opacity: 1,
    },
  },
  { name: 'playerCaptions' },
);
