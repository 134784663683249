import { useRef } from 'react';

import { generateMatchers } from '../logic';

const useMarkupWithMerging = (renderFn, matcherNames) => {
  const matchers = useRef(generateMatchers(renderFn, matcherNames));

  return {
    matchers: matchers.current,
  };
};

export default useMarkupWithMerging;
