import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    header: {
      position: 'relative',
      marginBottom: 80,
      padding: ['2%', 55, 0],
      zIndex: 1,
      [styling.breakpoints.up('sm')]: {
        padding: [0, 70],
      },
      [styling.breakpoints.up('md')]: {
        padding: [0, 270],
      },
    },
  },
  { name: 'header' },
);
