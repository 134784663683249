import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Image from '../../../../../components/Image';
import useStyles from './posterImage.styles';

const PosterImage = ({ posterImage, index, selectedIndex, count }) => {
  const styles = useStyles();
  // we only show 1 either side of the selected item
  // if offset > 3
  let offset = index - selectedIndex;
  if (offset > 3) {
    offset -= count;
  } else if (offset < -3) {
    offset += count;
  }

  return (
    <div
      className={clsx(
        styles.container,
        index === selectedIndex && styles.containerSelected,
      )}
      style={{
        '--posterImageOffset': offset,
        '--posterImageAbsOffset': Math.abs(offset),
        '--posterImageInView': offset < -1 || offset > 1 ? 0 : 1,
      }}
    >
      <Image className={styles.image} src={posterImage} forceInView />
    </div>
  );
};

PosterImage.propTypes = {
  posterImage: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default memo(PosterImage);
