import deepFreeze from '../helpers/deepFreeze';

const cacheFactory = (initialState = {}) => {
  let cache = deepFreeze(initialState);

  const getValueForKey = (key) => cache[key];

  const setValueForKey = (key, value) => {
    cache = deepFreeze({
      ...cache,
      [key]: value,
    });
    return cache;
  };

  const deleteValueForKey = (key) => {
    const { [key]: deleted, ...newCache } = cache;
    cache = deepFreeze(newCache);
    return cache;
  };

  return {
    getValueForKey,
    setValueForKey,
    deleteValueForKey,
  };
};

export default cacheFactory;
