import { useContext } from 'react';

import { MediaQueryContext } from '../../../../contexts/mediaQueryContext';
import useGlobalContent from '../../../../hooks/useGlobalContent';
import LoadWhenInView from '../../../LoadWhenInView';
import useStyles from './footerVideo.styles';

const FooterVideo = () => {
  const styles = useStyles();
  const { isDesktop } = useContext(MediaQueryContext);
  const {
    content: { footer },
  } = useGlobalContent();
  const { video } = footer || {};
  const videos = video?.[isDesktop ? 'desktop' : 'mobile'] || [];

  return (
    <div className={styles.wrapper}>
      <LoadWhenInView>
        <video
          key={isDesktop}
          autoPlay
          className={styles.video}
          crossOrigin="anonymous"
          loop
          muted
          playsInline
        >
          {videos.map(({ src, type }) => (
            <source key={src} src={src} type={type} />
          ))}
        </video>
      </LoadWhenInView>
    </div>
  );
};

export default FooterVideo;
