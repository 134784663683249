import PropTypes from 'prop-types';
import clsx from 'clsx';

import { PlayIcon } from '../../../../components/Icons';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import Markup from '../../../../components/Markup';
import MediaPlayer from '../../../../components/MediaPlayer';
import Typography from '../../../../components/Typography';
import useWhyVideo from './hooks/useWhyVideo';
import useStyles from './whyVideo.styles';
import WhyVideoInner from './WhyVideoInner';

const WhyVideo = ({ cta, video, loopVideo }) => {
  const styles = useStyles();
  const {
    playerSources,
    initialPlayerState,
    onVideoStateChange,
    isInnerReady,
    onInnerReady,
    hasYetToPlay,
  } = useWhyVideo(video, loopVideo);
  return (
    <div className={styles.videoWrapper}>
      <LoadingSpinner className={styles.spinner} />
      <div className={styles.videoInner}>
        {playerSources && (
          <MediaPlayer
            className={clsx(styles.player, isInnerReady && styles.playerReady)}
            initialState={initialPlayerState}
            isClickable={hasYetToPlay}
            onStateChange={onVideoStateChange}
            sources={playerSources}
            withOverlay={false}
          >
            {({ playing }) => (
              <WhyVideoInner
                cta={cta}
                loopVideo={loopVideo}
                onReady={onInnerReady}
                playing={playing}
              />
            )}
          </MediaPlayer>
        )}
      </div>
    </div>
  );
};

WhyVideo.propTypes = {
  cta: PropTypes.string.isRequired,
  loopVideo: PropTypes.object,
  video: PropTypes.object.isRequired,
};

WhyVideo.defaultProps = {
  loopVideo: undefined,
};

export default WhyVideo;
