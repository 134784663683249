import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '../../Typography';
import InputWrapper from '../InputWrapper';
import useInputCheckbox from './hooks/useInputCheckbox';
import Checkbox from './Checkbox';
import useStyles from './inputCheckbox.styles';

const InputCheckbox = ({
  className,
  required,
  disabled,
  errors,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  value,
  meta,
}) => {
  const styles = useStyles();
  const { name, checked, handleOnChange, toggleValue } = useInputCheckbox(
    onChange,
    value,
  );
  const LabelComponent = meta?.checkboxLabel;

  return (
    <div className={clsx(className, styles.wrapper)}>
      <input
        className={styles.input}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleOnChange}
        onFocus={onFocus}
        required={required}
        type="checkbox"
        checked={value}
        id={name}
        name={name}
      />
      <Checkbox
        isErrored={!!errors?.length}
        isFocused={isFocused}
        checked={value}
        disabled={disabled}
        onClick={toggleValue}
      />
      <div className={clsx(disabled && styles.disabledLabel)}>
        <LabelComponent htmlFor={name} />
      </div>
    </div>
  );
};

InputCheckbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.bool,
  meta: PropTypes.shape({
    checkboxLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  }),
};

InputCheckbox.defaultProps = {
  className: undefined,
  disabled: false,
  required: false,
  errors: undefined,
  isFocused: false,
  value: false,
  meta: undefined,
};

export default InputCheckbox;
