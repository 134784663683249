import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import { variantMapping } from '../../Typography/constants';
import {
  CLIP_PATH_SIZE_LG,
  CLIP_PATH_SIZE_MD,
} from '../InputWrapper/constants';

export default createUseStyles(
  {
    toggleButton: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_MD}px`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      boxSizing: 'border-box',
      height: '100%',
      background: 'none',
      border: 'none',
      padding: [0, 'calc(var(--selectClipPathSize) * 2)'],
      cursor: 'pointer',
      ...variantMapping.inputValue.styles,
      color: 'inherit',
      appearance: 'none',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      textShadow: 'inherit',
      '&:focus': {
        outline: 'none',
      },
    },
    toggleButtonLg: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_LG}px`,
      ...variantMapping.inputValueLg.styles,
      color: 'inherit',
    },
    caret: {
      position: 'absolute',
      right: 28,
      top: '50%',
      transition: styling.speeds.jog,
      transform: 'translate(0, -50%) rotate(0deg)',
    },
    caretRotated: {
      transform: 'translate(0, -50%) rotate(180deg)',
    },
    dropdownWrapper: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_MD}px`,
      maxHeight: 0,
      transition: styling.speeds.jog,
      display: 'flex',
      position: 'absolute',
      width: '100%',
      top: 'calc(100% + 1px)',
      zIndex: 5,
      '&:before': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 'calc(var(--selectClipPathSize) * -1)',
        height: 'var(--selectClipPathSize)',
        width: 'var(--selectClipPathSize)',
        background: styling.colors.theme.transparent,
        borderRight: [1, 'solid', styling.colors.theme.transparent],
        clipPath: `polygon(100% 0, 100% 100%, 1px 100%)`,
      },
    },
    dropdownWrapperLg: {
      '--selectClipPathSize': `${CLIP_PATH_SIZE_LG}px`,
      '&$dropdownWrapperOpen': {
        maxHeight: 420,
      },
      '& $dropdownOpen': {
        padding: [15, 0],
      },
      '& $item': {
        ...variantMapping.inputValueLg.styles,
        height: 72,
        padding: [0, 24],
      },
      '& $itemSelected': {
        color: styling.colors.theme.primaryLight,
      },
    },
    dropdownWrapperOpen: {
      maxHeight: 320,
      '&:before': {
        background: styling.colors.theme.pureBlackFadedMin,
        borderRightColor: styling.colors.theme.primaryLight,
      },
    },
    dropdownWrapperVariantcolored: {
      '& $item': {
        color: styling.colors.theme.primaryLight,
      },
    },
    filterInputWrapper: {
      padding: [20],
      boxSizing: 'border-box',
      width: '100%',
    },
    filterInput: {
      ...variantMapping.inputValueSm.styles,
      padding: [12],
      border: [1, 'solid', styling.colors.theme.pureWhite],
      boxSizing: 'border-box',
      width: '100%',
      background: [[styling.colors.theme.transparent], '!important'],
      color: [[variantMapping.inputValueSm.styles.color], '!important'],
      backgroundClip: [['content-box'], '!important'],
      appearance: 'none',
      textShadow: 'inherit',
      MozAppearance: 'textfield',
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&:-webkit-autofill,&:-webkit-autofill:focus': {
        transition: 'background-color 600000s 0s, color 600000s 0s',
      },
    },
    dropdown: {
      background: styling.colors.theme.pureBlackFadedMin,
      border: [0, 'solid', styling.colors.theme.transparent],
      borderTop: 'none',
      margin: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    dropdownOpen: {
      borderWidth: 1,
      borderColor: styling.colors.theme.primaryLight,
    },
    list: {
      margin: 0,
      padding: [8, 0],
      overflow: 'auto',
    },
    item: {
      ...variantMapping.inputValue.styles,
      transition: styling.speeds.jog,
      cursor: 'pointer',
      lineHeight: '2.4rem',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      padding: [0, 19],
      whiteSpace: 'pre',
      '& > b': {
        color: styling.colors.theme.primaryLight,
      },
    },
    itemSelected: {
      color: styling.colors.theme.primaryLight,
      background: styling.colors.theme.primaryLightFadedMax,
    },
    itemHighlighted: {
      background: styling.colors.theme.primaryLightFadedMax,
    },
  },
  { name: 'inputTypeahead' },
);
