import PropTypes from 'prop-types';
import clsx from 'clsx';

import styling from '../../../services/styling';
import IconSvg from '../../IconSvg';

const Times = ({ color, height, width, ...rest }) => (
  <IconSvg
    {...rest}
    color={color}
    height={height}
    viewBox="0 0 24 24"
    width={width}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={1}>
      <path
        vectorEffect="non-scaling-stroke"
        d="M0.912072 1.1287L24.6298 24.8465"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M24.6309 1.1287L0.913122 24.8465"
      />
    </g>
  </IconSvg>
);

Times.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Times.defaultProps = {
  color: styling.colors.theme.pureWhite,
  height: 24,
  width: 24,
};

export default Times;
