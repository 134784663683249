import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useMatches, useNavigate } from 'react-router';

import { AlertContext } from '../../../../contexts/alertContext';
import { AttemptContext } from '../../../../contexts/attemptContext';
import { PanelContext } from '../../../../contexts/panelContext';
import { asyncRequestStates } from '../../../../hooks/useAsyncRequest';
import useGlobalContent from '../../../../hooks/useGlobalContent';
import routes from '../../../../services/constants/routes';
import {
  handleOnCloseAlert,
  handleOnMount,
  handleOnNoResumeClick,
  handleOnResumeClick,
} from '../logic';

const useMissionRestorePrompt = () => {
  const {
    content: { missions },
    contentState,
  } = useGlobalContent();
  const { registerAlert } = useContext(AlertContext);
  const { getAttemptData, clearAttempt } = useContext(AttemptContext);
  const navigate = useNavigate();
  const matches = useMatches();

  const onNoResumeClick = useCallback(
    () => handleOnNoResumeClick(clearAttempt),
    [clearAttempt],
  );

  const onResumeClick = useCallback(
    (missionSlug) =>
      handleOnResumeClick(
        generatePath,
        routes.mission.childRoutes.missionSelected.fullPath,
        missionSlug,
        navigate,
      ),
    [navigate],
  );

  useEffect(() => {
    if (contentState !== asyncRequestStates.SUCCESS) return;

    handleOnMount({
      attemptData: getAttemptData(),
      registerAlert,
      onNoResumeClick,
      onResumeClick,
      missions,
      pathGenerator: generatePath,
      missionPath: routes.mission.childRoutes.missionSelected.fullPath,
      matches,
    });
  }, [contentState]);
};

export default useMissionRestorePrompt;
