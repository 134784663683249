import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: [84, 45, 50, 84],
    },
    close: {
      marginBottom: 6,
      alignSelf: 'end',
    },
    primaryNav: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      marginBottom: 45,
    },
    secondaryNav: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
    },
  },
  { name: 'mainNavPanel' },
);
