import PropTypes from 'prop-types';
import clsx from 'clsx';

import Fade from '../../../components/Fade';
import { PlayIcon } from '../../../components/Icons';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Markup from '../../../components/Markup';
import MediaPlayer from '../../../components/MediaPlayer';
import Typography from '../../../components/Typography';
import useHomeVideo from './hooks/useHomeVideo';
import useStyles from './homeVideo.styles';
import HomeVideoInner from './HomeVideoInner';

const HomeVideo = ({ body, cta, video, loopVideo }) => {
  const styles = useStyles();
  const {
    playerSources,
    initialPlayerState,
    controls,
    onVideoStateChange,
    isInnerReady,
    onInnerReady,
  } = useHomeVideo(video, loopVideo);
  return (
    <>
      {/* fullbleed video player */}
      <div className="full-bleed">
        <div className={styles.videoWrapper}>
          <LoadingSpinner className={styles.spinner} />
          <div className={styles.videoInner}>
            {playerSources && (
              <MediaPlayer
                className={clsx(
                  styles.player,
                  isInnerReady && styles.playerReady,
                )}
                controls={controls}
                initialState={initialPlayerState}
                isClickable
                onStateChange={onVideoStateChange}
                sources={playerSources}
                withOverlay={false}
              >
                {({ playing }) => (
                  <HomeVideoInner
                    cta={cta}
                    loopVideo={loopVideo}
                    onReady={onInnerReady}
                    playing={playing}
                  />
                )}
              </MediaPlayer>
            )}
          </div>
        </div>
      </div>
      {body && (
        <div className={styles.bodyWrapper}>
          <Typography component="div" variant="body">
            <Markup content={body} />
          </Typography>
        </div>
      )}
    </>
  );
};

HomeVideo.propTypes = {
  body: PropTypes.string,
  cta: PropTypes.string.isRequired,
  loopVideo: PropTypes.object,
  video: PropTypes.object.isRequired,
};

HomeVideo.defaultProps = {
  body: undefined,
  loopVideo: undefined,
};

export default HomeVideo;
