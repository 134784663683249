import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import Typography from '../Typography';
import { variantMappingKeys } from '../Typography/constants';
import useAnimatedNumber from './hooks/useAnimatedNumber';
import useStyles from './animatedNumber.styles';
import { ANIMATION_DURATION } from './constants';

const AnimatedNumber = ({ variant, prepend = '', append = '', value }) => {
  const styles = useStyles();
  const { ref, inView } = useAnimatedNumber();

  return (
    <Typography ref={ref} variant={variant} withShadow>
      {value ? (
        <CountUp
          duration={ANIMATION_DURATION}
          end={inView ? value : 0}
          prefix={prepend}
          suffix={append}
        />
      ) : (
        <span className={styles.noData}>-</span>
      )}
    </Typography>
  );
};

AnimatedNumber.propTypes = {
  variant: PropTypes.oneOf(variantMappingKeys).isRequired,
  prepend: PropTypes.string,
  append: PropTypes.string,
  value: PropTypes.number.isRequired,
};

AnimatedNumber.defaultProps = {
  prepend: undefined,
  append: undefined,
};

export default AnimatedNumber;
