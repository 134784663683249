import { createUseStyles } from 'react-jss';

import styling from '../../../../../services/styling';

const ICON_SCALE = 0.75;

export default createUseStyles(
  {
    '@keyframes glow': {
      '0%': { filter: 'none' },
      '50%': {
        filter: styling.shadows.utils.svgFilter(
          styling.shadows.theme.primaryDeepDarkXlFaded,
        ),
      },
      '100%': { filter: 'none' },
    },
    '@keyframes float-h-sm': {
      '0%': { transform: 'translate(10, -10px) rotate(0.001deg)' },
      '50%': { transform: 'translate(0, -10px) rotate(0.001deg)' },
      '100%': { transform: 'translate(10, -10px) rotate(0.001deg)' },
    },
    '@keyframes float-h-sm-v-md': {
      '0%': { transform: 'translate(10, -6px) rotate(0.001deg)' },
      '50%': { transform: 'translate(0, -14px) rotate(0.001deg)' },
      '100%': { transform: 'translate(10, -6px) rotate(0.001deg)' },
    },
    '@keyframes float-h-sm-v-sm': {
      '0%': { transform: 'translate(10, -15px) rotate(0.001deg)' },
      '50%': { transform: 'translate(0, -25px) rotate(0.001deg)' },
      '100%': { transform: 'translate(10, -15px) rotate(0.001deg)' },
    },
    '@keyframes float-h-sm-v-lg': {
      '0%': { transform: 'translate(20px, 20px) rotate(0.001deg)' },
      '50%': { transform: 'translate(10px, 0px) rotate(0.001deg)' },
      '100%': { transform: 'translate(20px, 20px) rotate(0.001deg)' },
    },
    '@keyframes float-h--xs': {
      '0%': { transform: 'translate(-8px, 0) rotate(0.001deg)' },
      '50%': { transform: 'translate(0, 0) rotate(0.001deg)' },
      '100%': { transform: 'translate(-8px, 0) rotate(0.001deg)' },
    },
    '@keyframes float-h-xs': {
      '0%': { transform: 'translate(8px, 0) rotate(0.001deg)' },
      '50%': { transform: 'translate(0, 0) rotate(0.001deg)' },
      '100%': { transform: 'translate(8px, 0) rotate(0.001deg)' },
    },
    '@keyframes fade': {
      '0%': { opacity: 0.45 },
      '50%': { opacity: 1 },
      '100%': { opacity: 0.45 },
    },
    '@keyframes grow-fade': {
      '0%': {
        opacity: 0.7,
        transform: 'scale(0)',
      },
      '100%': {
        opacity: 0,
        transform: 'scale(1)',
      },
    },
    '@keyframes shake': {
      '0%': { transform: 'translate(0)' },
      '20%': { transform: 'translate(-2px, 2px)' },
      '40%': { transform: 'translate(-2px, -2px)' },
      '60%': { transform: 'translate(2px, 2px)' },
      '80%': { transform: 'translate(2px, -2px)' },
      '100%': { transform: 'translate(0)' },
    },
    '@keyframes spin': {
      '100%': { transform: 'rotate(360deg)' },
    },
    wrapper: {
      height: 0,
      width: '70%',
      paddingBottom: '70%',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -40%)',
      filter: styling.shadows.utils.svgFilter(
        styling.shadows.theme.primaryDeepDarkLgFaded,
      ),
      [styling.breakpoints.up('md')]: {
        width: '55%',
        paddingBottom: '55%',
        transform: 'translate(-50%, -49%)',
      },
    },
    wrapperGlow: {
      animationName: '$glow',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationFillMode: 'forwards',
      animationTimingFunction: 'ease-in-out',
    },
    icon: {
      backgroundSize: 'contain',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
    'float-h-sm': {
      animationName: '$float-h-sm',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-fade': {
      animationName: '$float-h-sm-v-md, $fade',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-h--xs': {
      animationName: '$float-h--xs',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-h-sm-v-md': {
      animationName: '$float-h-sm-v-md',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-h-sm-v-sm': {
      animationName: '$float-h-sm-v-sm',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-shake': {
      animationName: '$float-h-sm-v-sm, $shake',
      animationIterationCount: 'infinite',
      animationDuration: [styling.speeds.saunter, styling.speeds.mosey],
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-grow-fade': {
      animationName: '$float-h-sm-v-sm, $grow-fade',
      animationIterationCount: 'infinite',
      animationDuration: [styling.speeds.saunter, styling.speeds.mosey],
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      top: -15,
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-h-sm-v-lg': {
      animationName: '$float-h-sm-v-lg',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      top: 50,
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    'float-h-xs': {
      animationName: '$float-h-xs',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.saunter,
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'paused',
      '&$animating': {
        animationPlayState: 'running',
      },
    },
    animating: {},
  },
  { name: 'missionIcon' },
);
