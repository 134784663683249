import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import usePersistentStorage from '../hooks/usePersistentStorage';

export const PersistentStorageContext = createContext({
  removeAllValues: () => undefined,
  removeValue: () => undefined,
  retrieveValue: () => undefined,
  storeValue: () => undefined,
});

export const PersistentStorageProvider = ({
  children,
  namespace,
  storageClass,
}) => {
  const value = usePersistentStorage(namespace, storageClass);

  return (
    <PersistentStorageContext.Provider value={value}>
      {children}
    </PersistentStorageContext.Provider>
  );
};

PersistentStorageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  namespace: PropTypes.string,
  storageClass: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
};

PersistentStorageProvider.defaultProps = {
  namespace: '',
};
