import { useContext } from 'react';
import PropTypes from 'prop-types';

import TextSwish from '../../../../../../../components/TextSwish';
import Typography from '../../../../../../../components/Typography';
import { MediaQueryContext } from '../../../../../../../contexts/mediaQueryContext';

const QuestionTitle = ({ title }) => {
  const { isDesktop } = useContext(MediaQueryContext);

  return (
    <Typography
      variant={`questionTitle${isDesktop ? '' : 'Mobile'}`}
      withShadow
    >
      {title}
      <TextSwish isCentered />
    </Typography>
  );
};

QuestionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default QuestionTitle;
