import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';
import spaceship from '../../../static/images/spaceship.webp';

export default createUseStyles(
  {
    '@keyframes spaceshipFloat': {
      '100%,0%': {
        transform: `
					translate(10px, 0)
					rotate(2deg)
				`,
      },
      '33%': {
        transform: `
					translate(0px, 3px)
					rotate(0deg)
				`,
      },
      '66%': {
        transform: `
					translate(10px, -3px)
					rotate(-2deg)
				`,
      },
    },
    wrapper: {
      position: 'absolute',
      right: 0,
      overflow: 'hidden',
      height: 280,
      width: 270,
      top: 130,
      display: 'none',
      pointerEvents: 'none',
      zIndex: 10,
      [styling.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
    spaceship: {
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundImage: `url(${spaceship})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 415,
      height: 280,
      animationName: '$spaceshipFloat',
      animationIterationCount: 'infinite',
      animationDuration: '10s',
      animationDirection: 'alternate',
      animationTimingFunction: 'ease-in-out',
    },
  },
  { name: 'spaceship' },
);
