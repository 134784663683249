import { useCallback, useEffect, useState } from 'react';

import styling from '../services/styling';
import useMediaQuery from './useMediaQuery';

const useMediaQueryManagement = () => {
  const isXs = useMediaQuery(styling.breakpoints.up('xs'));
  const isSm = useMediaQuery(styling.breakpoints.up('sm'));
  const isMd = useMediaQuery(styling.breakpoints.up('md'));
  const isLg = useMediaQuery(styling.breakpoints.up('lg'));
  const isXl = useMediaQuery(styling.breakpoints.up('xl'));

  return {
    isXs,
    isSm: isSm && isXs,
    isMd: isMd && isSm && isXs,
    isLg: isLg && isMd && isSm && isXs,
    isXl: isXl && isLg && isMd && isSm && isXs,
    isDesktop: isMd && isSm && isXs,
  };
};

export default useMediaQueryManagement;
