import Toast from '../Toast';
import useToastRoot from './hooks/useToastRoot';

const ToastRoot = () => {
  const { toasts, topToast, closeToast } = useToastRoot();

  return (
    <>
      {toasts.map((toast) => {
        const toastKey = toast.key;
        const toastProps = toast.config || {};
        return (
          <Toast
            key={toastKey}
            id={toastKey}
            {...toastProps}
            hasTopToast={!!topToast}
            isIn={topToast === toastKey}
          />
        );
      })}
    </>
  );
};

export default ToastRoot;
