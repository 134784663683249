import { memo, useRef } from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from '../../../components/LoadingOverlay';
import Markup from '../../../components/Markup';
import MediaPlayer from '../../../components/MediaPlayer';
import SlantedButton from '../../../components/SlantedButton';
import TextSwish from '../../../components/TextSwish';
import Typography from '../../../components/Typography';
import { htmlDecode } from '../../../services/helpers/string';
import { createVimeoEmbedUrl } from '../../mission/scenes/missionSelected/ActiveMission/SteppedQuestion/VideoEmbedQuestionStep/logic';
import useStyles from './metaSupport.styles';

const initialPlayerState = {
  playing: false,
  muted: false,
};

const MetaSupport = ({ content }) => {
  const styles = useStyles();
  const { title, body, video, supportCta, supportCtaLink } = content;
  const { vimeoEmbedId } = video;

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title} variant="primaryNav" withShadow>
        {htmlDecode(title)}
        <TextSwish isCentered />
      </Typography>
      <Typography className={styles.body} component="div" variant="subtitle">
        <Markup content={body} />
      </Typography>
      <div className={styles.videoWrapper}>
        <div className={styles.videoInner}>
          <LoadingOverlay className={styles.loading} size={100} />
          {vimeoEmbedId && (
            <MediaPlayer
              initialState={initialPlayerState}
              sources={createVimeoEmbedUrl(vimeoEmbedId)}
              withOverlay={false}
            />
          )}
        </div>
      </div>
      <SlantedButton
        className={styles.button}
        component="a"
        href={supportCtaLink}
        size="lg"
      >
        {supportCta}
      </SlantedButton>
    </div>
  );
};

MetaSupport.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    video: PropTypes.shape({
      vimeoEmbedId: PropTypes.string.isRequired,
    }).isRequired,
    supportCta: PropTypes.string.isRequired,
    supportCtaLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(MetaSupport);
