import { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useRequestsManagement from '../hooks/useRequestsManagement';

export const RequestsContext = createContext([async () => {}]);

export const RequestsProvider = ({ children }) => {
  const value = useRequestsManagement();

  return (
    <RequestsContext.Provider value={value}>
      {children}
    </RequestsContext.Provider>
  );
};

RequestsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
