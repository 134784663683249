/* eslint-disable no-console */
const loggerLevelWrapper =
  (logFn) =>
  (...msgs) => {
    if (process.env.NODE_ENV === 'development') {
      logFn(...msgs);
    }
  };

const debug = (...msgs) => {
  console.log(...msgs);
};

const info = (...msgs) => {
  console.info(...msgs);
};

const trace = (...msgs) => {
  console.trace(...msgs);
};

const error = (...msgs) => {
  console.error(...msgs);
};

export default {
  debug: loggerLevelWrapper(debug),
  info: loggerLevelWrapper(info),
  trace,
  error,
};
