import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    label: {
      transition: styling.speeds.jog,
    },
    error: {
      color: styling.colors.theme.error,
      textShadow: styling.shadows.theme.errorDarkLg,
    },
  },
  { name: 'inputLabel' },
);
