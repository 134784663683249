import { useCallback, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

import { handleOnMount, handleTrackEvent } from './logic';

const useAnalyticsManagement = () => {
  const enabledRef = useRef(false);

  const setEnabledRef = useCallback((isEnabled) => {
    enabledRef.current = isEnabled;
  }, []);

  useEffect(() => {
    handleOnMount(
      ReactGA.initialize,
      process.env.REACT_APP_G_MEASUREMENT_ID,
      setEnabledRef,
    );
  }, []);

  const trackEvent = useCallback(
    (eventDetails) =>
      handleTrackEvent(enabledRef.current, ReactGA.send, eventDetails),
    [],
  );

  return {
    trackEvent,
  };
};

export default useAnalyticsManagement;
