import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { useSpring } from 'react-spring';

import useTileDimensions from './useTileDimensions';

const calculateStyles = (
  offset,
  width,
  height,
  scale,
  isInitalState,
  spacing,
  numMissions,
  dragRef,
) => {
  const dir = Math.min(1, Math.max(-1, offset));
  const absOffset = Math.abs(offset);
  /*
    due to translate we position from the center of our tiles
    to get our left value we need to:
    - 1) a - add half the width of the unscaled center tile plus the spacing
    - 2) b - get half the width of our scaled tile
    - 3) c - get the width of our scaled tile plus the spacing
    - by adding a to b we all additional values will position
      from the center of the first scaled tile. As such we want to
      reduce our offset by 1 so that the first scaled tile adds nothing
    - 4) to calculate our left position then we want to:
      add a to b
      add 1 less than our offset amount of c
      multiply the result by the direction the tile is from the center

    _____  _____  _________  _____  _____
    |___|  |___|  |_______|  |___|  |___|
                      ^---a--^
                              ^b^
                                  ^--c--^
  */
  const a = width / 2 + spacing;
  const b = (scale * width) / 2;
  const c = scale * width + spacing;
  let left = dir * (a + b + (absOffset - 1) * c);

  if (isInitalState) {
    return {
      transform: 'translate(-50%, 0) scale(1)',
      opacity: 0,
      top: 100,
    };
  }

  // Calculate the width of screen you can see
  const numTiles = 5;
  const viewWidth =
    width / 2 + (width * scale + spacing) * ((numTiles - 1) / 2);

  if (dragRef.dragging) {
    // Shift by the drag offset
    left += dragRef.xOffset;

    const dist = Math.abs(dragRef.xOffset);
    const dragDir = dragRef.xOffset / dist;

    const teleViewWidth =
      width / 2 + (width * scale + spacing) * ((numTiles + 6 - 1) / 2);

    if (dragDir < 0 && left < -teleViewWidth) {
      left += (numMissions - 1) * width * scale + width * 2 - spacing / 2;
    } else if (dragDir > 0 && left > teleViewWidth) {
      left -= (numMissions - 1) * width * scale + width * 2 - spacing / 2;
    }
  }

  const opacity = left > -viewWidth && left < viewWidth ? 1 : 0;

  return {
    transform: `translate(-50%, 0) scale(${dir ? scale : 1})`,
    opacity,
    left,
    top: 0,
  };
};

const useTileStyle = (offset, dragApi, numMissions) => {
  const { width, height, scale, cornerRad, spacing } = useTileDimensions();
  const dragRef = useRef({
    xOffset: 0,
    dragging: false,
  });

  const [springStyles, api] = useSpring(() => ({
    config: { friction: 25, mass: 3, precision: 0.001 },
    to: calculateStyles(
      offset,
      width,
      height,
      scale,
      false,
      spacing,
      numMissions,
      dragRef.current,
    ),
  }));

  const update = useCallback(() => {
    api.start(
      calculateStyles(
        offset,
        width,
        height,
        scale,
        false,
        spacing,
        numMissions,
        dragRef.current,
      ),
    );
  }, [offset, width, height, scale, false, spacing, dragRef.current]);

  // Update if any properties change
  useEffect(update, [update]);

  // register for updates
  useEffect(() => {
    const onDrag = (down, [x]) => {
      dragRef.current.xOffset = x;
      dragRef.current.dragging = down;
      debounce(
        () => {
          update();
        },
        100,
        {
          leading: true,
          trailing: true,
        },
      )();
    };

    dragApi.register(onDrag);
    return () => {
      dragApi.unregister(onDrag);
    };
  }, [update, dragApi]);

  const styles = useMemo(
    () => ({
      ...springStyles,
      '--missionTileHeight': `${height}px`,
      '--missionTileWidth': `${width}px`,
    }),
    [springStyles, height, width],
  );

  return {
    springStyles: styles,
    cornerRad,
  };
};

export default useTileStyle;
