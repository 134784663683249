import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    wrapper: {
      margin: [0, 0, 40],
    },
    titleScoreWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  { name: 'statsDisplayBar' },
);
