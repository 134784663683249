import getOrFallbackInObj from '../../../../services/helpers/getOrFallbackInObject';
import styling from '../../../../services/styling';
import {
  CIRCLE_RADIUS,
  SECONDARY_VALUE_OFFSET,
  TYPE_COLOR_MAPPING,
} from './constants';

const getMaxValueForType = (type) => {
  if (type === 'score') {
    return 1 * 3 + 98 * 9;
  }
  return 100;
};

const calculateLineLengthFromValue = (circum, value, type) =>
  Math.floor(circum * (value / getMaxValueForType(type)));

const getStrokeColorFromType = (type) =>
  getOrFallbackInObj(TYPE_COLOR_MAPPING, type, 'score');

export const getCircumference = () => Math.floor(Math.PI * CIRCLE_RADIUS * 2);

export const generateMainSize = (type, circum, value) => ({
  // line as long as the value
  dasharrayStart: calculateLineLengthFromValue(circum, value, type),
  dasharrayEnd: circum,
});

export const generateMainStroke = (type, circum, value) => {
  const returnValue = {
    stroke: getStrokeColorFromType(type),
    strokeDashoffset: 0,
    strokeLinecap: value ? 'round' : '',
    style: {
      '--statWheelPrimaryLineBlurColor': getStrokeColorFromType(type),
    },
  };
  if (type === 'increase') {
    const dashArray = generateMainSize(type, circum, value);
    return {
      ...returnValue,
      style: undefined,
      strokeDasharray: `${dashArray.dasharrayStart}, ${dashArray.dasharrayEnd}`,
    };
  }
  return returnValue;
};

export const generateSecondaryStroke = (circum, value) => ({
  stroke: getStrokeColorFromType('score'),
  strokeLinecap: value ? 'round' : '',
  // offset by the length of the main value
  strokeDashoffset:
    calculateLineLengthFromValue(circum, value + SECONDARY_VALUE_OFFSET) * -1,
});

export const generateSecondarySize = (circum, secondaryValue) => ({
  // line as long as the value
  dasharrayStart: calculateLineLengthFromValue(
    circum,
    secondaryValue - SECONDARY_VALUE_OFFSET,
  ),
  dasharrayEnd: circum,
});
