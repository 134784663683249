import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      height: '100%',
      flexGrow: 1,
      position: 'relative',
      minHeight: 1000,
    },
    image: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '50%',
      left: 0,
      backgroundSize: 'contain',
      maxHeight: 1000,
      translate: '0 -50%',
    },
  },
  { name: 'metaLanding' },
);
