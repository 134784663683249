import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { PLAYER_STATE_EVENTS } from '../../../../../components/MediaPlayer/constants';
import { MediaQueryContext } from '../../../../../contexts/mediaQueryContext';

const useHomeVideoInner = (loopSources, playing, onReady) => {
  const initialLoopPlayerState = useRef({
    playing: true,
    muted: true,
  });
  const [hasPlayed, setHasPlayed] = useState(!loopSources);

  // TODO: portrait video doesnt respect object fit cover :(
  const { isDesktop } = useContext(MediaQueryContext);
  const loopPlayerSources = loopSources?.[isDesktop ? 'desktop' : 'mobile'];
  // const loopPlayerSources = loopSources?.desktop;

  useEffect(() => {
    if (playing) {
      setHasPlayed(true);
    }
  }, [setHasPlayed, playing]);

  const onVideoStateChange = useCallback(
    (event, value) => {
      if (event === PLAYER_STATE_EVENTS.READY) {
        onReady();
      }
    },
    [onReady],
  );

  return {
    loopPlayerSources,
    initialLoopPlayerState,
    hasPlayed,
    onVideoStateChange,
  };
};

export default useHomeVideoInner;
