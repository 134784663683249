import { useContext, useMemo } from 'react';

import { AlertContext } from '../../../contexts/alertContext';
import { getOnBackdropClick } from '../logic';

const useAlertRoot = () => {
  const { closeAlert, topAlert, alerts } = useContext(AlertContext);

  const onBackdropClick = useMemo(
    () => getOnBackdropClick(alerts, topAlert, closeAlert),
    [closeAlert, topAlert],
  );

  return {
    alerts,
    topAlert,
    closeAlert,
    onBackdropClick,
  };
};

export default useAlertRoot;
